.seats-legend {
  padding-bottom: 10px;
  margin-bottom: 10px;
  border-bottom: 1px solid #eeeeee;
  text-align: center;
  display: flex;

  .seat {
    text-align: center;
    font-size: 13px;
    flex: 1;
    line-height: 15px;

    .num {
      font-family: "open sans", Arial, serif;
      font-size:11px;
      font-weight: 600;
    }
    .seat-icon  {
      margin: 0 auto 3px auto;
    }
  }
}