.passenger-details-wrapper {
  .chk-autofill {
    float: left;
    cursor: pointer;
  }

  .flight-info-wrapper {

  }
}

.p-details {
  margin: 20px 0 55px 0;
  display: flex;

  .col-num {
    flex:0;
    margin-left: 20px;
    padding-top: 25px;
  }

  .col-details {
    flex:1;

    .row {
      display: flex;
      margin-bottom: 20px;

      .col-field {
        width: 180px;
        margin-right: 25px;

        .title {
          color: #404042;
          font-size:14px;
          font-weight: 700;
          margin-bottom: 8px;
          opacity: 0.4;
        }

        &:first-child {
          margin-right: 0;
        }

        input {
          height: 32px;
          box-sizing: border-box;
          border:1px solid #e9e9e9;
          border-radius: 4.5px;
          padding: 0 8px;

          &.ltr {
            direction: ltr;
            text-align: right;
          }
        }

        .validation-msg {
          font-size: 12px;
          margin-top: 5px;
          color: #f50605;
          height: 20px;
        }

        .input-warning {
          border: solid 1.5px #f50605;
          color: #f50605;
        }

      }
      .checkbox {
        width: 16px;
        height: 16px;
        margin-left: 10px;
        border: solid 1.8px #464646;
        border-radius: 4.5px;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
      }

      .checkbox.checked {
        background-color: #2076a2;
        border: solid 1.8px #2076a2;
      }

      .checkbox.checked::after {
        content: "";
        width: 3px;
        height: 8px;
        border: 2px solid white;
        border-left: none;
        border-top: none;
        transform: rotate(45deg);
      }
    }
  }
}

.static-field {
  background: #f3f3f3;
  border-radius: 4.5px;
  padding: 0 8px;
  height: 32px;
  line-height: 32px;

  font-size:13px;
  font-family: "Open Sans", "Arial", serif;
}

// MOBILE
@media only screen and (max-width: 1024px) {
  h3 {
    font-size: 24px;
    margin-top: 25px;
    margin-bottom: 25px;
    font-weight: 700;
  }
  .passenger-details-wrapper {
    .chk-autofill {
      float: left;
    }
  }
  .p-details {
    flex-direction: column;
    border-top: 1px solid #d5d5d5;

    &:first-child {
      border-top:0;
    }

    .p-num {
      display: flex;
      width: 30px;
      height: 30px;
      border-radius: 50%;
      background: #f3f3f3;
      align-items: center;
      justify-content: center;
      font-family: "Open Sans", "Arial", serif;
      font-size:18px;
      font-weight: 700;
      margin-bottom: 10px;
    }

    .col-details {
      .row {
        flex-direction: column;
        margin-bottom: 0;

        &.row-ads {
          flex-direction: row;
        }

        .col-field {
          margin-right: 0;
          width: auto;
          margin-top: 8px;

          .static-field {
            padding-right: 10px;
            height: 43px;
            line-height: 43px;
            font-size: 13px;
            font-family: "Open Sans", "Arial", serif;
          }

          .title {
            margin-bottom: 3px;
            padding-right: 10px;
          }

          input {
            box-sizing: border-box;
            width: 100%;
            border:solid 1.5px rgba(151, 151, 151, 0.5);
            height: 43px;
            font-size:16px;
          }
        }
      }
    }
  }
}