.cs-extra-services {
    margin-top: 30px;

    &__title {
      display: flex;
      font-size: 35px;
      font-weight: 300;
      margin-bottom: 30px;

      .main {
        font-weight: 700;
        margin-left: 6px;
        margin-right: 10px;
      }
    }

    .cs-bluebird-vip-logo {
      margin-bottom: 12px;
    }

    &__content {
      display: flex;

      h3 {
        font-size: 24px;
        margin-top: 0;
        margin-bottom: 6px;
      }

      .btn {
        white-space: nowrap;
        padding-left: 32px;
        padding-right: 32px;
          .plus-sign {
            margin-left: 10px;
          }
      }
  
      p {
        line-height: 1.56;
        padding-left: 26px;
        margin-top: 0;
      }
    }

  .little-text {
    font-size: 14px;
  }

}