
.details-col-hotel {
  font-size:16px;
  align-items: center;
  justify-content: center;
  display: flex;
  font-weight: 700;
  position: absolute;

  left:0;
  top:70px;
}

.cs-hotel {
  .hotel-name {
    font-family: "open sans", Arial, serif;
    font-size: 14px;
    font-weight: bold;
    display: flex;
  }


  .hotel-stars {
    display: inline-block;
    direction: ltr;
    >.wrap {
      display: flex;
      margin-top: 1px;
      margin-right: 16px;
    }
  }

  .hotel-dates {
    margin-top: 5px;
    font-size: 16px;
    .date {
      font-family: "open sans", Arial, serif;
      font-size: 14px;
    }
  }

  .hotel-rooms {
    margin-top: 10px;
    display: table;

    .hotel-room {
      display: table-row;
      margin-top: 5px;
      align-items: baseline;

      .col {
        display: table-cell;
        padding-left: 16px;
        padding-top: 5px;
        margin-right: 20px;
        &:first-child {
          margin-right: 0;
        }
      }
    }
    .num {
      font-size:14px;
    }
  }
}
.icon {
  &.icon-hotel {
    background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACwAAAAeCAMAAABg6AyVAAAAKlBMVEUAAAD///9VVVVHR0dLS0tGRkZHR0dHR0dGRkZGRkZHR0dGRkZHR0dGRkZ982ESAAAADXRSTlMAAQYZLGaPlL3I1fP943qDywAAAHZJREFUeNrM0cUBgDAQBMC4Hdd/uThx/bHPzcQJniGz+Rmm0gJYSb8uDjglYszN0xsecJxNM4/pa09Nq/gcYB+WoZQNjPrDNnS2hTfxYggdtDCqFewWjoEwf8Ez8093ZuJTQqa+u8DHYICaijHBiFVMdIYdEYoBb6cpanuePTAAAAAASUVORK5CYII=);
    width: 22px;
    height: 15px;
    background-size: 22px 15px;
  }

  &.star {
    width: 18px;
    height: 18px;
    background-size: 18px 18px;
    background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACgAAAAoCAMAAAC7IEhfAAABOFBMVEUAAAD//////4D/qlX/v0D/qiv/nyD/jhz/mRr/ixf/iRT/iBH/hw//hgz/hQv/igv/iQr/iAn/hAj/gwj/hwf/gwf/hgb/hgb/hAX/hAX/ggT/ggT/ggT/gwT/gwT/gQP/gwP/gQP/gQP/gwP/gwP/gwP/gQP/ggb/hAX/ggX/ggX/ggX/gwX/gwX/ggX/ggT/gwT/gwT/ggT/ggT/gQT/ggT/gQT/ggT/ggT/ggT/ggT/ggT/gQP/gQP/gQP/gQP/ggP/ggP/ggP/ggT/ggT/ggT/ggT/ggT/ggT/ggT/ggT/gQT/ggT/gQT/ggT/ggT/gQT/gQT/ggT/ggP/gQP/ggP/ggP/gQP/ggP/gQP/ggP/gQP/ggP/ggP/ggP/ggP/gQP/ggP/gQP/ggP/gQP/ggP/gQP/gQO10XCHAAAAZ3RSTlMAAQIDBAYICQoLDQ8RFRcYGh4fISQnKCo0ODk9P0JGSUpLTU5QUlNaX2BkamttbnJ1eXp+gIGChYeLjY+UlqCio6WnrbO2t7m7v8jLzM/Q0tPX2Nzd3uDh4uPm6+7w8vT3+Pn6+/z9bY5HnQAAAP9JREFUeNrt0oNyBTEUgOFT27bdW9u2bbf/+z9BcbWbac5shuU3WP2rJPK9JDl2iROOYdNzgVu4xphTVwqXqS7hFNDh0GXeAFsOYYgPFYFdwkE4nA8Mawl7yA164BKEDYlNQWVT58Dk/NrOxRMxd2dbyzPjfe315TleWH2Fbr9IPCVHaNazxS9nA7u5FDGlLWIzbFtcfPJon8quZ0zXNWLXcGt0J2WimTPCHlEdGuGC2uVhOFfDVkyFWjiJqU0LNwlbat4mbFrpMsJL57hRJCl0DbCrhDXA/UhaeD93FnjJsoeDsFIsMVV7UGcPV09bxCe592bUHvaniym/W/45egMX+4qEogAgygAAAABJRU5ErkJggg==);

    &.half {
      background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACoAAAAoCAMAAAC/1ZhiAAABm1BMVEUAAAD+hBL/gAX/gQX/gQb/ggT/ggn/gxD/hAz/hA3/hBP/hRP/hhX/hh7/hyD/iCT/iST/iSX/iib/iiv/jCz/jS//jjH/jzL/jzT/kDX/kDf/kjn/lD3/lD//lT7/lkD/lkH/lkL/l0X/mEb/mEf/mUf/mUj/m07/nVH/nlL/nlP/oFX/o13/pFz/pF7/pWD/pWH/pmH/pmP/qGT/qWn/q2r/rGz/rG3/rG//rXL/rnL/sHX/sXb/snj/s3n/toH/t4H/uIL/uIT/uoX/uob/u4f/vIj/vIn/vIr/vYv/vYz/vo3/w5f/xJn/xZv/xpz/yaH/yaL/yqP/zqf/z6j/z6n/0Kr/0a3/0a7/0q3/07L/1rf/1rn/2Lr/2bv/2bz/2r7/3MD/3ML/3cP/38f/4Mj/4cr/4sv/487/5NH/5dP/6NX/6tn/69z/7d3/7d//7eD/7uH/7+H/7+L/8OP/8uf/8+j/9Oz/9ez/9e3/9e//9u3/9u7/9u//+fb/+vX/+vf/+/b/+/f//Pn//Pr//fz//v3///7///9H+0jYAAAAAXRSTlMAQObYZgAAAV9JREFUeNq80QN37EAAQOF9uc+2q2Vt27Zt2zbnXxezxmznqPcg+pIMTEIr00NPRm/0aaU23Xw5oUurSNekZ594saFHu4BKPWoFPp/q0GmwQKcOzeZ5iRmLBt1+BaWdMPU4rQfKjj+TeROa7s2P9TZXF6Q5/rw3HqiogNc/bEl55Y3do3NbvnQ9EnfPgVKx9gIDV99mfanYT8CLn5cKMdnXVJmfHA383wgY62Wul3JPZXuxz4k/Cp5WA4Z0cqyy5X+QcxFqBbpf+X91+gvUKhZr4iPg+Wr/W950KNd18bdhuOkOfBgPswUtPl/9SaEIQ7N8aA4R4eh3ZLal+/M2OFDTVQxk7+pOxQKMqGmPdNL/Gbz6QI2aFiNzDKcCKTHEqWkUwNeOGyGG/hrA2wsVPQBeFB3K8/OGZ8CMig6DfVa4W0mEJhW9mzt/QBty0EWLG+NSGlSBlnnqfcktNEeVAgA0WpgVMvrKrQAAAABJRU5ErkJggg==);
    }

    &.empty {
      background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACsAAAAoCAMAAABQF/NcAAAB11BMVEUAAAD+gwz+hBL+hRX+hhr+hxz/gAX/gQX/gQb/ggT/ggn/gwn/gwz/hBL/hhb/hhj/hh7/hxz/hx3/iiv/iyn/jCz/jS7/jjH/jzL/jzT/kDf/kTj/kzz/lD3/lD7/lT//lUL/lkL/l0P/mET/mEf/mUf/mUn/mUz/mkv/mkz/mk3/m07/nlL/oFX/oFf/oVj/olv/o1z/pF3/pV//pmH/pmP/qGT/qmr/q2r/rG3/rG7/rG//rW//rXD/rXH/sHX/sHb/sXj/snr/tX3/tX7/tn//toD/t4D/t4H/t4L/uIT/uYT/uoX/vIj/vIv/vYz/vo3/wJP/wpX/wpb/w5f/w5j/xJj/xJn/xpz/xp3/x57/yJ//yaH/zqj/z6r/0Kr/0Kz/0az/0a7/0a//0q//0rH/1bT/1bX/1rf/17j/17n/2Lr/2Lz/2bv/2bz/2b3/27//3ML/3cL/3cP/38f/487/5M7/5M//5ND/5NH/5dD/5dL/5dP/5tL/5tT/59b/6Nf/69z/7Nz/7uH/7+H/8OT/8eT/8eX/8uf/9Or/9ev/9u//9vH/9/D/9/H/9/L/+fP/+fT/+vf/+/n//Pj//Pn//Pr//fr//fv//vv//v3///7///9bQ/kNAAAAAXRSTlMAQObYZgAAAYJJREFUeNq80oN25kAAhuHf79re2rZt27Zt27Y791q7Tc4c9YuTJ2ONkIvmIq9pT+XtXqa8rTGsS1tXimTtIPw/lbQRQKecXfkI+MjZbAiBaRl7+Bvv5Y8kyNhGaBdhfNmWsO78OxEDUKpk5/s7KvJSwr3tf2mh8OKFE+j+OvpFphfVdk9sPLJdXwB4A6BfvXhRzX2clx+3YfIPYOcfnVFSP3T5vN9anpMc7Pkb3gTsPG3vkr0Jr03xOGO/IPHked+2fMFm4RFtN/K25MVxOI6BX6MPaNk7PjcrjVn+W5Ox7W69p2L6PqI8vg2f0O3f3DeB5ZzaXATgcHs7C3VCzf4g6e7+J7FqdgYaLi4TmZeDF4itmq2EJbER957v1UKU8HZNxUa9+SeqvpsA3KbGoUvFmuHidiFDW6zgQ/wHspTtyhsA6z4hjgq/Ango22bgW8HR9foIBD4fKdrz+bCzW3TAecVy7OyVONVqyxYhh9LkYJZIXGpnhE9DS5PtcZSWqaNqAdcCOJFuUiLaAAAAAElFTkSuQmCC);
    }
  }
}