@font-face{
  font-family:'almoni-dl';
  font-weight:300; /*(light)*/
  font-style: normal;
  src: url('../../fonts/almoni-dl-aaa-300.eot');
  src: url('../../fonts/almoni-dl-aaa-300.eot?#iefix') format('embedded-opentype'),
  url('../../fonts/almoni-dl-aaa-300.ttf') format('truetype'),
  url('../../fonts/almoni-dl-aaa-300.woff') format('woff');
}
@font-face{
  font-family:'almoni-dl';
  font-weight:400; /*(regular)*/
  font-style: normal;
  src: url('../../fonts/almoni-dl-aaa-400.eot');
  src: url('../../fonts/almoni-dl-aaa-400.eot?#iefix') format('embedded-opentype'),
  url('../../fonts/almoni-dl-aaa-400.ttf') format('truetype'),
  url('../../fonts/almoni-dl-aaa-400.woff') format('woff');
}
@font-face{
  font-family:'almoni-dl';
  font-weight:700; /*(bold)*/
  font-style: normal;
  src: url('../../fonts/almoni-dl-aaa-700.eot');
  src: url('../../fonts/almoni-dl-aaa-700.eot?#iefix') format('embedded-opentype'),
  url('../../fonts/almoni-dl-aaa-700.ttf') format('truetype'),
  url('../../fonts/almoni-dl-aaa-700.woff') format('woff');
}
@font-face{
  font-family:'almoni-dl';
  font-weight:900; /*(black)*/
  font-style: normal;
  src: url('../../fonts/almoni-dl-aaa-900.eot');
  src: url('../../fonts/almoni-dl-aaa-900.eot?#iefix') format('embedded-opentype'),
  url('../../fonts/almoni-dl-aaa-900.ttf') format('truetype'),
  url('../../fonts/almoni-dl-aaa-900.woff') format('woff');
}

@import url(//fonts.googleapis.com/css?family=Open+Sans:700,600,400,300);