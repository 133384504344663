.p-gender {
  border-radius: 4.5px;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.2);
  background-color: #fff;
  height: 32px;
  box-sizing: border-box;
  display: flex;
  overflow: hidden;
  cursor: pointer;
  margin-bottom: 8px;

  .gen {
    font-family: "Open Sans", "Arial", serif;
    font-size: 13px;
    font-weight: 600;
    flex:1;
    justify-content: center;
    align-items: center;
    text-align: center;
    display: flex;

    &.active {
      background-color: #cee1f9;
      color: #164397;
    }

    &.active:hover {
      background-color: #9CB5D8;
    }
  }

  .gen:hover {
    background-color: #f7f7f7;
  }
}