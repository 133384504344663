
.imp-info-container {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin: 20px 0;
}

.imp-info-text {
  white-space: pre-wrap;
  color: #ff8103;
  font-size: 16px;
  line-height: 18px;
  margin-right: 20px;
  max-width: 600px;
  .baggage-warn {
    font-weight: 600;
    font-size: 16px;
  }
}