.flight-status {
  font-size:16px;
  margin-right: 10px;
  display: flex;

  color:#4db70b;
  align-content: center;
  align-items: center;
  justify-content: center;
  font-weight: 600;


  .icon-confirmation,
  .icon-in-progress {
    margin-left: 10px;
  }

  &.warn {
    color: #de4d2b;
  }
}

@media only screen and (max-width: 1024px) {
  .flight-status {
    justify-content: right;

  }
}