@import 'app/styles/theme';

.cs-login-form {
  .header-image {
    
    @if ($theme == "bluebird") {
      background: url('~app/desktop/images/bluebird/login-logo.jpg') no-repeat top center;
    } @else {
      background: url('~app/desktop/images/login_logo.jpg') no-repeat top center;
    }
    width: 460px;
    height: 122px;
    background-size: 460px 122px;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    overflow: hidden;
  }

  width: 460px;
  border-radius: 4px;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16), 0 3px 6px 0 rgba(0, 0, 0, 0.23);
  background-color: #ffffff;

  color:#01527a;
  padding: 0;
  box-sizing: border-box;

  .form-body {
    padding: 0 73px 47px 73px;

    .row {
      margin-top: 24px;

      &.reset-password {
        text-align: right;
        margin-top: 12px;
        min-height: inherit;
      }
    }
  }

  .form-header {
    text-align: center;

    .line-1 {
      font-size:44px;
      font-weight: 900;
    }

    .line-2 {
      font-size:28px;
      padding-top: 5px;
      font-weight: 700;
    }

    .line-1,
    .line-2 {
      line-height: 29px;
    }

    p {
      font-size:18px;
    }

    .line-3-title {
      white-space: nowrap;
      display: inline-block;
      position: relative;
      left: 50%;
      transform: translateX(-40%);
    }
  }

  .button-placeholder {
    margin: 29px 48px 0 48px;
  }

  .buttons {
    margin-top: 29px;
    display: flex;
    .btn {
      width: 100%;
      margin-right: 15px;
      &:first-child {
        margin-right: 0;
      }
    }
  }

  .activity-container {
    min-height: 30px;

      .error {
        font-size: 16px;
      }
  }

  .recaptcha-container {
    display: flex;
    justify-content: center;
  }
}