@import 'app/styles/helpers';

.sidebar-table {
  a {
    color: #FFFFFF;
  }
  .st-row {
    display: flex;
    font-size: 14px;

    &.total {
      margin-top: 16px;
      line-height: 19px;
    }

    &.passenger {
      margin-top: 4px;
    }

    .col1 {

    }

    .col2 {
      flex:1;
      text-align: left;
      font-family: "Open Sans", Arial, serif;
      font-weight: 600;
      font-size:12px;

      .heb {
        font-size: 16px;
        font-weight: bold;
      }

      &.total-price {
        font-size:14px;
        color:#ff8103;
        font-weight: 700;
      }
    }
  }
}