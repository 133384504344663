@import 'app/styles/helpers';

.modal-baggage-wrapper {
  text-align: center;

  .ScrollbarsCustom-Track {
    height: calc(100% - 100px) !important;
    top: 13% !important;
    left: 7px !important;
  }

  .ScrollbarsCustom-TrackY {
    height: calc(100% - 100px) !important;
    top: 13% !important;
    left: 7px !important;
  }

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.85);
  z-index: 9998;
}

  .popup-wrapper {
    display: flex;
    box-sizing: border-box;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 90%;
    height: 90%;
    background-color: white;
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16), 0 3px 6px 0 rgba(0, 0, 0, 0.23);
    overflow-y: auto;
    z-index: 9999;


    @media (max-width: 767px) {
      max-width: 100%;
      max-height: 100%;
    }
  }

  .popup-wrapper-resolution {
    max-width: 803px;
    max-height: 543px;
    padding: 20px;
  }

  .boarding-pass-resolution {
    max-width: 613px;
    max-height: 412px;
    padding: 30px;
  }

  .modal-baggage-close {
    position: absolute;
    right: 20px;
    top: 15px;
    color:#9b9b9b;
    cursor: pointer;
    opacity: 1;
    z-index: 99999;

      @include transition(opacity, 0.5s , ease);

      &:hover {
        opacity: 0.5;
      }

      &:after {

        position: absolute;
        font-size: 40px;
        content: "\D7";
        font-weight: normal;
        font-family: serif;
        display: block;
        top:0;
        right:0;
      }
  }

}
