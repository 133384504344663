@import 'app/styles/helpers';
@import 'app/styles/theme';

.btn {
  @include prevent-selection();

  &.btn-regular {
    height: 46px;
    border-radius: 4px;
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.2);
    background-color: #ffffff;
    font-size: 16px;
    color: $linkColor;
    text-align: center;
    font-weight: 700;
    font-family: "almoni-dl", Arial, serif;
    line-height: 47px;
    cursor: pointer;
    @include transition(all, 0.5s, ease);

    &:hover {
      background-color: #e5edf1;

    }

    &.big {
      height: 57px;
      line-height: 57px;
      font-size: 20px;
      padding: 0 18px;
      position: relative;
    }

    &.small {
      height: 35px;
      line-height: 35px;
      font-size: 14px;
      font-weight: 700;
      padding: 0 15px;

    }
  }
}


//Mobile
@media only screen and (max-width: 1024px) {

  .btn {
    &.btn-regular {
      font-size: get_vw_from_px(12px);
    }
  }
}