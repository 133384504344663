.chk-baggage {
  height: 100%;

}

.bp-form-main-div {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}

.bp-form-text-block {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 286px;
  height: 75px;
  font-size: 24px;
  font-weight: bold;
  text-align: center;
  white-space: pre-wrap;
}

.bp-form-text-block p {
  margin: 0;
}

.bp-form-second-div {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
  margin-bottom: 80px;
  width: 100%;
  text-align: right;
  cursor: pointer;
}

.bp-form-bottom-button {
  position: absolute;
  left: 0;
  bottom: 0;
}

.bp-form-bottom-button-inner {
  width: 88px;
}

.boarding-pass-done-mark {
  width: 47px;
  height: 47px;
  background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADUAAAA1CAMAAADWOFNCAAAABGdBTUEAALGPC/xhBQAAAAFzUkdCAK7OHOkAAABaUExURUdwTP///////////////////////////////////////////////////////////023C1S6FNPtw+Hz1cjptI7RY5zXeH/LTrPglr3lpafbhnPGP2K/KG3ENvFPmF0AAAAPdFJOUwAp6YIOatj4HJdBBIGouP/4WZQAAAHFSURBVEjHtZbZkoMgEEXdwUQjaFTc/v83Z1gFREiYmn4yFCdNXy4NSXITBUxhkXwRadlWoKMBqrZMP0FgU3VmVA0MMTXoHFH7uCJ3MnSp+W2Nj0xOGsg0zsc8TmSQQ9nDDZUiUT/t6Ix96kW60sE8a8FsyI5NcPXzAr340mbkipkv9GVjPNMbI3fgN89m1cQGV3QfK5th1PZgQozIFyOTRFOyyEKZVLbs3Lec1YRCwWrLlY3o+gYcpDBVEkBdvxmFY9Z0ZKl6Xw4yDFyp/kzGqto8UK8E3s7KKn8qDgmx6HfFTi4dm+4h7gpBTfQzlbbY/XIrC+zSIC2VPQgR8ZuK34qyyKcQIqIw4ClrEdCiRmhh4NeDhm+PcfNC3MNFAnVjrMYUB8TtAbnwh8hkTCKdrjnSpqRGrlH/byckc+l1HdqSFISvZ7MwNVyU0Ksbkhqa+yX3RzbP3j51cr8Mb0jX3UHKG6YPhcPvIOVDy/MadoVOz9vnS2GuTqLOV9JYZ1lgLoid5cbdNxjm7Fla37j2KDwtKw70qMh++FXvbf7Y5+PulMj7K/KujLyX494Ake+N2LdN5Dvq9s0GavgP78PYt+hH794foeyB9SJTabMAAAAASUVORK5CYII=) no-repeat;
  background-size: 47px 47px;
}

.icon-baggage {
  width: 40px;
  height: 29px;
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAFAAAAA6CAMAAAAQoC6jAAABa1BMVEUAAACAgID///+AgID///9VVVVVVVVOTk5JSUlNTU1OTk5KSkr///9MTExJSUn///9KSkpJSUlISEhJSUn///9HR0dJSUlISEhJSUlGRkZHR0dISEhHR0f///9HR0f///9GRkb///////9HR0dGRkZHR0dHR0dHR0dISEhISEhGRkZHR0dHR0dGRkb///9HR0f///9HR0dGRkZGRkZGRkZHR0dHR0f///9HR0f///////////9GRkZHR0dHR0f///9GRkZHR0dHR0f///9GRkZHR0f///9GRkZHR0dGRkb///////9HR0f7+/tHR0fb29v///9GRkZGRkZHR0dGRkb///9GRkZHR0dLS0tOTk5PT09YWFhbW1thYWFwcHB1dXV3d3eFhYWJiYmQkJCTk5OUlJSVlZWpqam8vLy9vb3Q0NDX19fZ2dna2tri4uLj4+Po6Ojv7+/y8vL19fX29vb6+vr8/Pz+/v7///+32Sd2AAAAVnRSTlMAAgIEBwkMDQ4UFxgZGxweMDE5Ozs9RkdJUFNVVlhaXWZnbnB0dnp+gIuRl52gpKWlrLW2ubq+v8PIycvM0dnZ2tvf4OHn6ezt7/Lz+Pj5+fn6+/z+/tARZpEAAAIUSURBVHjaYsAKJEw9wpCAn4MmB5oKHkU9c3tvb3tzPUUeBkKAVScMA7iIIilgUbIMrYCDUEslFkC79cD2SBSDYThr27Zt2/Zb97ONtvn529TImeacNe5L5TOeCUU6CcWLNVSxcN8jbvFo30Jy2/QZmotUtu4WK26tI6cz0K0lsfkpq55uJpfb0G2joj3v2eH9HnJ4ALFpad1liF2yfrXe1FB3JpHIdA9N1YqbI4MbqO4SxG6iddXtnehCTddEdavX+QYXVo5Hvh9N+vOVI7PQM7iPS2a70KJrlkv2+QUXP2KR70KbrjyLR4u9gge5pLa98qa21VxykMTWC3efNPgE8exJ3WuIF+9YTEALYoLFDSJadho2qQKLLj3YxeLLaqJzMOpjMQk9iCkWB2gHrEZYDLmCQyzO01VYjbLodgW7WVyjF7CaYpEpp5qhKMPiHsFsnkXCFUyweEUwm2ORdAWTLF4Sft0mGw8KzIYtp81ZAr7ziQ0z46UHuzHTzQF2gxG3r4Ha7Qt28WnLDRYeei2PAHiIjRseUvCRnun8GIWXbI4rJl0PevjpyXUaReApO9NhWIKv9Hj0OAdvsd7pqIETAeKDYwWu+XKzaSRGmFTf8OjUG21oxzc4SmV/YfB/8H/wf/Ajwh0hxUOE20uKUwj2cSUpVj1DqOOk2vkBYa4vIt36Owjw4Zj0dAs27j/s6dCuFaT7ClAvHPx8kImXAAAAAElFTkSuQmCC);
  background-size: 40px 29px;
  margin-left: 12px;
}

.icon-info-popup {
  width: 19px;
  height: 19px;
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAEQAAABECAMAAAAPzWOAAAABTVBMVEUAAAD////////////////M///V///j4//M5ubR6Ojb7e3M3e7P3+/M5ubO5+fT3unQ4+zM3ebP3+fS4enM4unM3+bQ4efM3ejN3unP3+TQ3ufM3+jN3+jP4OXP3OXN3OjO3eTO3eXM3ubN3+bN3+bP3efO3uXM3+bN3ObN3+XO3eXN3ubO3ubO3eXN3ObM3eTN3uXO3eTM3uXN3OXO3OTM3OTN3eXN3eXN3ObO3eTN3eXN3uXN3eTM3uXN3eXM3eXM3ubN3OTM3eXN3eXN3eXN3eXM3eTN3OXN3eXM3OTM3eXM3eXN3OTM3eTN3eTN3eTM3eTM3OXN3OXM3eXM3eXN3OTM3OTM3OXM3OTN3OTM3eXM3eXN3eTN3eTM3eTM3OXM3OXN3OXN3eXM3eXM3eTN3OTM3OTM3OXM3eXN3eXN3eUBUnpSiqWkwc/M3OSwmzZVAAAAa3RSTlMAAQIDBAUGCQoLDg8QFBUXGx4gIiMoKy0uMDY3ODo7QkNERkdISk5QUVdZXF1iZ2lscnR1fH1/gISGiYqQkZScoKKmp66ztLe7vsPEx8nK0dPU1tfY29zi4+Tn6O7v8PHy8/T19vn6+/z9/s4cIRwAAAHuSURBVHjapdCDmhxREEDh05u1bdu2bdtGzySp9/8YdLDD26j/AU6BpFJrBmY3ji/v7eebs+2FkbZs/MofXH+UaPbeVCXeWR1rH5LQwVAOnnzpOZbk7ifzcNdwImZPw6mYFSyLu8N6TBquxQt7NIVkrPGQeLSZR2Kpi+LdaRGJZKyLH1flxEtdF39uSohlLYpf5wXEGBf/9tOJ0hCSAOaIVHAtgXQSYUWCucmNOEaCmuef1BMJKlTFX70S3CoOrGNJ7qtDkgqX4+gQRUSWcKypIq/ZAPkfqoj0AQyKLrIFsK6MvGdB6qMyIq1QK0bfHWIyDQOitQmzonUBG2L0zSEm4UyORftYKeNKH6nmQR9pxNZH2nnRR5q50UfqONNHKtjWR/JZUEduYUQd2YU2dWQBsm1tpBfY00YKgSll5BSgUhmZ4LcDXaSU34ZUkR0cOfei0AmOSQnuyAJH3pME1s0/wxLUjsU/qYcSjF3Jp3pbApkh0pgEsZdGpJRN8e+umGh5p+LXWxOxiq7En1AX8cpv/DX6SaTk3M8tXSRWsC9e3TWRTPqceLNXjEHnjbizZ9Iwyp0PiYudSlxVrYbF4KjbwovypVdJYqfTwqvsvq13iXM6UYo/Wa3Tmxdh+et2d6G3kGAyy6ob21vqKvIx+gmgbfP2oMJ1mQAAAABJRU5ErkJggg==);
  background-size: 19px 19px;
  margin-left: 10px;
}

.baggage-info-text {
  cursor: pointer;
}

.baggage-table-wrap {
  padding: 0 50px;

  .bp-section {
    display: flex;
    flex-direction: column;
    max-width: 400px;
    margin: auto;
  }

  .bp-header {
    font-size: 28px;
    line-height: 25px;
    margin-bottom: 10px;
  }

  .bp-text {
    margin-top: 0;
    white-space: nowrap;
  }

  .bg-underline {
    text-decoration-color: black;
    text-underline-offset: 0.1em;
  }

  .bp-footer-text {
    text-align: right;
    line-height: 20px;
    font-size: 14px;
    padding-bottom: 70px;
  }

  .icon-row {
    display: flex;
    grid-template-columns: repeat(7, 1fr);
    gap: 30px;
    align-items: baseline;
    justify-items: center;
    text-align: center;
    padding-bottom: 60px;
    justify-content: center;
  }

  .trolley-icon {
    height: 80px;
  }

  .hebrew-spacing {
    display: inline-block;
    direction: ltr;
  }

  .icon-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    //justify-content: flex-end;
    width: 100px;
  }

  .multi-icons {
    display: flex;
    align-items: baseline;

    >:first-child {
      margin-left: 10px;
    }
  }

  .separator {
    align-self: center;
    font-size: 15px;
    margin-bottom: 15px;
  }

  .icon-container h3 {
    font-size: 15px;
    font-weight: bold;
    margin-top: 10px;
    margin-bottom: 2px;
  }

  .icon-container p {
    margin-top: auto;
    font-size: 15px;
    line-height: 19px;
  }

  .text-row {
    margin-top: -40px;
  }

  .second-row {
    grid-template-columns: repeat(2, 0.3fr);
    justify-content: center;
    margin-top: 20px;

    .icon-container {
      width: 200px;
    }
  }
}

.baggage-table {
  .row {
    margin-top: 7px;
    display: flex;

    &.header {
      margin-top: 20px;

      color: #b3b3b3;
      font-size: 14px;
      font-weight: bold;
    }


    .col {
      margin-right: 15px;
      min-height: 1px;
      display: inline-block;

      &:first-child {
        margin-right: 0;
      }
      &.c-num {
        width: 31px;
      }

      &.c-name {
        width: 190px;

      }

      &.c-direction {
        width: 93px;
      }
      &.c-baggage {
        width: 281px;

        .sub-row {
          margin-top: 10px;
          &:first-child {
            margin-top: 0;
          }

          &.message {
            margin-top: 1px;
            padding-right: 3px;
            font-size: 13px;
            color:#878789;
          }
        }
      }

      &.c-price {
        width: 70px;

      }
    }

    &:not(.header) {
      .col {
        &.c-name {
          color:#878789;

          >.wrap {
            height: 32px;
            border-radius: 4.5px;
            background-color: #f3f3f3;
            line-height: 32px;
            box-sizing: border-box;
            padding: 0 10px;
          }
        }
        &.c-price {
          font-size:23px;
          font-weight: 700;
        }
      }

    }
  }

  .name-index-wrap {
    align-items: center;
    margin-bottom: 20px;
  }

  .bg-name-wrap {
    margin-right: 32px;
    font-size: 15px;
  }

  .p-num {
    width: 32px;
    height: 32px;
    border-radius: 50%;
    background-color: #f3f3f3;
    color:#464646;
    font-size:18px;
    font-weight: 700;
    text-align: center;
    line-height: 32px;
  }

  .section {
    margin-bottom: 30px;
  }

  .section-header {
    font-size: 24px;
    font-weight: bold;
    padding: 15px 60px 15px 0;
  }

  .bg-container {
    max-width: 620px;
    display: flex;
    //width: 90%;
    margin: 0 62px 15px 4px;
    //margin-bottom: 15px;
    //margin-right: 62px;

    //margin-left: 4px;
    display: flex;
    .bg-wrap {
      flex:0;
      display: flex;
      min-height: 95px;

      box-shadow: 0 1px 3px 1px rgba(0, 0, 0, 0.1), 0 1px 2px 1px rgba(0, 0, 0, 0.1);
      border-radius: 4.5px;      
    }

    .bg-row {
      flex-direction: row;
      display: flex;
      justify-content: center;
      flex:1;
    }
    .bg-col {
      padding: 5px 15px;
      text-align: center;
      flex: 1 1 0px;
      display: flex;
      flex-direction: column;
      width: 206px;
      box-sizing: border-box;


      border-left:2px solid #f3f3f3;
      &:last-child {
        border-left: none;
      }

      .bg-info {
        display: flex;
        flex-direction: column;

        flex:1;
        .title {
          margin-top: 20px;
        }

        .text {
          margin-bottom: 5px;
          flex:1;
        }
      }

      input {
        margin: 5px 0 3px 0;
      }


      &:first-child {
        background: #f3f3f3;
      }

      .title {
        font-size: 14px;
        font-weight: bold;
        margin-bottom: 2px;
      }

      .icon {
        padding: 10px 10px 0 10px;
        height: 59px;
        justify-content: center;
        align-items: center;
        display: flex;

        >.wrap {
          position: relative;
          .green-mark {
            position: absolute;
            top:-10px;
            right: -20px;
          }
        }
      }

      .text {

      }
    }
  }

  .left-part {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 65%;
    padding: 15px;
  }

  .bg-left-part-purchased {
    justify-content: flex-start;
  }

  .right-part {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-evenly;
    width: 204px;
    background-color: #f3f3f3;
    padding: 15px;

    box-sizing: border-box;
  }

  button {
    margin-right: 10px;
  }

  .baggage-svg {

  }

  .included-header {
    font-size: 14px;
    font-weight: bold;
    margin-bottom: 2px;
  }

  .selected-text-container {
    padding-top: 6px;
  }

  .info {
    font-size: 14px;
    color: #878789;
  }

  .icon-backpack {
    max-width: 31px;
    max-height: 36px;
  }

  .icon-big-trolley {
    max-width: 35px;
    max-height: 59px;
  }

  .bg-dropdown-wrap {
    width: 60%;
    margin-right: 5px;
    padding-right: 10px;
  }

  .bg-dropdown-wrap-selected {
    width: 50%;
  }

  .dropdown-with-label .wrap .label {
    background-color: unset;
  }

  .dropdown-with-label .options .options-wrap {
    background-color: #ffffff;
  }
  .dropdown-with-label .wrap:hover {
    background-color: #ffffff;
  }

  .dropdown-with-label .options {
   background-color: #ffffff;
    max-width: 100%;
  }

  .bg-purchased-padding {
    padding-right: 25px;
  }

  .btn.btn-blue {
    padding: 0 22px;
    line-height: 1;
    margin-top: 12px;
    height: 38px;
  }

  .bg-backpack-container {
    align-self: flex-end;
  }

  .bg-backpack-header {
    padding-bottom: 6px;
  }

  .green-mark {
  margin-bottom: 25px;
  //margin-left: -5px;
  }

  .big-trolley-mark {
    margin-bottom: 50px;
  }
}