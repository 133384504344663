@import 'app/styles/helpers';
@import 'app/styles/theme';

.generic-modal {
  position: fixed;
  width: 100%;
  height: 100%;
  visibility: hidden;
  opacity: 0;
  left:0;
  top:0;

  @include transition(all, 0.5s , ease);

  .cs-popup-bg {
    position: fixed;
    width: 100%;
    height: 100%;
    left:0;
    top:0;
    background-color: rgba(255, 255, 255, 0.85);
  }

  &.open {
    visibility: visible;
    opacity: 1;

    .cs-popup-wrap {
      right: 0;
    }
  }

  .cs-popup-wrap {
    position: absolute;
    box-sizing: border-box;
    width: 1000px;
    height: 100%;
    right: -1000px;
    background: #FFFFFF;
    box-shadow: 0 0 11px 0 rgba(0, 0, 0, 0.19), 0 0 6px 0 rgba(0, 0, 0, 0.26);
    padding: 30px 0px 0px 0px;
    @include transition(right, 0.5s , ease);
    overflow-y: auto;
  }

  .cs-popup-close {
    position: absolute;
    left:30px;
    top:15px;
    color:#9b9b9b;
    cursor: pointer;
    opacity: 1;

    @include transition(opacity, 0.5s , ease);

    &:hover {
      opacity: 0.5;
    }

    &:after {

      position: absolute;
      font-size: 40px;
      content: "\D7";
      font-weight: normal;
      font-family: serif;
      display: block;
      top:0;
      left:0;
    }
  }

  header {
    font-size: 35px;
    font-weight: bold;
    display: flex;
    align-items: center;
    padding-bottom: 26px;
    border-bottom: 1px solid #eeeeee;

    margin: 0 40px 0 29px;

    img {
      margin-left: 10px;
    }

  }

  section {
    flex:1;
    padding: 0 40px 0 29px;
  }

  footer {
    flex: none;
    height: 82px;
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16), 0 -3px 6px 0 rgba(0, 0, 0, 0.23);
    padding: 12px 20px 0 20px;
    box-sizing: border-box;
    z-index: 1;
    overflow: hidden;
  }

  .cs-popup-content {
    display: flex;
    flex-direction: column;
    height: 100%;

    .ticket-text-red {
      color: #ff8103;
    }

  }

  .ScrollbarsCustom.ScrollbarsCustom-Track,
  .ScrollbarsCustom-Track.ScrollbarsCustom-TrackY{
    left:-12px;
  }
}

@media only screen and (max-width: 1024px) {
  .generic-modal {
    .cs-popup-close {
      left: 15px;
      top: 12px;
      color: #464646;
    }
    .cs-popup-wrap {
      width: 100%;
      padding: 0;
      right: -100vw;
    }

    header {
      font-size: 25px;
      margin: 0 14px;
      height: 65px;
      display: flex;
      justify-items: center;
      padding: 0;
    }

    footer {
      height: 74px;
      box-shadow: none;
      border-top-left-radius: 23px;
      border-top-right-radius: 23px;
      @if ($theme == "bluebird") {
        background-image: linear-gradient(#7498ce 15%, #164397 99%);
      } @else {
        background-image: linear-gradient(#2079a6 15%, #2b3f48 99%);
      }

    }

    section {
      padding: 0;
      margin-bottom: -15px;

      .scroll-window {
        position: relative;
        width: 100%;
        height: 100%;
        .scroll-window-wrap {
          position: absolute;
          width: 100%;
          height: 100%;
          .scroll-window-scroll {
            overflow: hidden scroll;
            position: absolute;
            height: 100%;
            width: 100%;
            padding: 20px 15px 0 15px;
          }
        }
      }
    }
  }
}