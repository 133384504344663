@import 'app/styles/helpers';
@import 'app/styles/theme';

.passengers-list {
  position: sticky;
  top: 0;
  padding-top: 5px;

  .passenger {
    box-sizing: border-box;
    border-radius: 5px;
    padding: 12px;
    margin-top: 5px;
    border: 2px solid #FFFFFF;
    cursor: pointer;
    @include transition(border-color, 0.5s, ease);


    &:first-child {
      margin-top: 0;
    }

    &.active {
      border: 2px solid $pNumBgColor;

      .p-num {
        background: $pNumBgColor;
        color: #FFFFFF;
      }
    }

    &:hover {
      border: 2px solid $pNumBgColor;

    }

    .seat-num {
      font-family: "Open Sans", Arial, serif;
      font-size: 13px;
    }

  }

  .p-num {
    width: 32px;
    height: 32px;
    border-radius: 50%;
    background-color: #f3f3f3;
    color: #464646;
    font-size: 18px;
    font-weight: 700;
    text-align: center;
    line-height: 32px;
    @include transition(all, 0.5s, ease);

  }

  .row {
    margin-top: 7px;
    display: flex;

    &.header {
      margin-top: -2px;

      color: #b3b3b3;
      font-size: 14px;
      font-weight: bold;
    }


    .col {
      margin-right: 25px;
      min-height: 1px;
      display: inline-block;

      &:first-child {
        margin-right: 0;
      }

      &.c-num {
        width: 31px;
        flex: none;
      }

      &.c-name {
        width: 144px;
        flex: none;
      }

      &.c-seat {
        width: 72px;
        position: relative;
        flex: none;
      }

      &.c-button {
        width: 114px;

        .btn {
          position: relative;
          margin-top: -7px;
        }
      }

      &.c-direction {
        width: 93px;
      }

      &.c-baggage {
        width: 281px;

        .sub-row {
          margin-top: 10px;

          &:first-child {
            margin-top: 0;
          }

          &.message {
            margin-top: 1px;
            padding-right: 3px;
            font-size: 13px;
            color: #878789;
          }
        }
      }

      &.c-price {
        width: 70px;

      }
    }

    &:not(.header) {
      .col {
        &.c-name {
          color: #878789;

          > .wrap {
            height: 32px;
            border-radius: 4.5px;
            background-color: #f3f3f3;
            line-height: 32px;
            box-sizing: border-box;
            padding: 0 10px;
          }
        }

        &.c-price {
          font-size: 16px;
          font-weight: 700;
          line-height: 32px;
        }
      }

    }
  }

  .btn-remove {
    width: 19px;
    height: 19px;
    border-radius: 50%;
    background: #ccdce4;
    cursor: pointer;
    display: inline-block;
    position: absolute;
    left: 8px;
    top: 7px;

    @include transition(all, 0.5s, ease);


    &:hover {
      background: #2076a2;

      &:after {
        color: #ccdce4;
      }
    }

    &:after {
      display: block;
      position: absolute;
      top: 8px;
      right: 3px;
      font-size: 22px;
      content: "×";
      font-weight: 700;
      font-family: serif;
      line-height: 0;
      color: #2076a2;
    }
  }

  .seatPaid {
    opacity: 0.4;
    pointer-events: none;
  }

  .allPaid {
    opacity: unset;
  }

  .return-icon {
    width: 9px;
    height: 9px;
    border: 3px solid;
    border-right-color: transparent;
    border-radius: 50%;
    position: relative;
    display: inline-block;
    color: $linkColor;
    margin-right: 15px;
    align-self: center;
    cursor: pointer;
  }

  .return-icon:after {
    content: '';
    position: absolute;
    right: -6px;
    top: -3px;
    border: solid transparent;
    border-width: 5px;
    border-left-color: $linkColor;
    z-index: 1;
    transform: rotate(28deg);
  }

  .return-text {
    font-size: 16px;
    font-weight: bold;
    cursor: pointer;
    margin-right: 4px;
  }

}
  .paid-text {
    justify-content: center;
  }

  .warning-sticky {
    margin-top: 20px;
  }