.dlg-flight-details {

  .webcheck-row {
    padding-right: 0!important;
  }

  .flight-status {
    margin-right: 50px;
  }

  .cs-flight {
    position: relative;
    border-top:5px solid #eeeeee;
    padding-bottom: 100px;
    padding-top: 70px;

    &:first-child {
      border-top:none;
      padding-top: 0;
    }
    .bg-info-wrapper {
      margin-top: 0;
      padding-right: 40px;
    }
    .bg-info-margins {
      margin: 15px 0 15px;
    }
  }

  .row {
    &.destinations {
      display: flex;
      align-items: center;
      font-size: 24px;
      font-weight: bold;
    }

    &.airline {
      font-family: "open sans", Arial, serif;
      font-size: 15px;
      font-weight: bold;
      margin-top: 20px;
      padding-right: 40px;
    }

    &.flight-number {
      font-size:18px;
      padding-right: 40px;
      padding-top: 18px;
      font-weight: normal;
    }

    .f-number {
      font-family: "open sans", Arial, serif;
      font-size: 15px;
      font-weight: 600;
      margin-top: 20px;
    }

    &.flight {
      font-size:18px;
      padding-right: 40px;
      padding-top: 18px;

    }

  }



  .icon-baggage,
  .icon-seats {
    width: 30px;
    height: 30px;
    background-size: 30px 30px;

    margin-left: 10px;
  }

  .icon-baggage {
    background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADwAAAA8CAAAAAAfl4auAAAA7UlEQVRIx+3XoQ6DMBCA4T5dgxlJLQ5BMgWaV5jhKZhCsBfBkaB4hKZNSWoaWAGDKV17IVlIf3Xm03eHFp2SgjOHuJBqdWhZZsk8kvOK54l5Nc0aS+aZXJBi3ikk/bFEwh8LxP0xRwzQ3fAni/BWlLWu+I0P1W6YPo44pk6416R4bRV67J1wp0Wzj40eu//GY5U/t1Itkn1M9JjuY16NZjwQbIkMRlxia6UREzsmRox/KOCAAw74QhzZbWTEmR1nRtzacWveGHV8TuP6bFfRvjupp/e+hq7HoMMVdDKDjnXQmwB6UGCvEegpA7yDXwMShfQbobSHAAAAAElFTkSuQmCC);
  }

  .icon-seats {
    background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADwAAAA8CAAAAAAfl4auAAABvUlEQVRIx83Xu0vDQBwH8Px11+CLDqIiIqggghStVAcRUUFxclBaC7WDKb5AxMWipaCoFQcVWzt0ER/0oUjrhRRuCYmXtAm0VZL8Tqnf5RcOPiR3F+7BqTQykUTsIKJEZM1xqqoQDAhRNKyUMShlhWKCgSEqJ2NwZI7AMeEkOJY4EY5FDjPkr3Ep8w7Fn0I7cs1nYXgTaRkH4WKbjtE5BKcrFgkQnK/iKKjPM7rtyoHwyyi1nQngPJfi4cNsU/6wh4hwqdXTQCCQdoZLfm28vE8Yr9Eac4YXKzPlhWB/dZrRlXO8ZVgUcYxDpkVBh/h5DkHx60YHAuHksTDB66bFOR4xiO8MjHv2cQqI+3c/MASPDU6FUvpTCjpVzcL5rJZrED5AtREc4HpL1+1Vu7jBDhXxMi0JG7jB8ncYD9OascYNto++8YLWbusFULdun5HJhb03Ovi9tHHFElfsTW3j7QBtbH20woY9iZo52pnlv93suJ/e667vOFqyWvTj5jfXY1fIcscoeIz+1mJ+Omljuyl4qmMVXjcT3I7l7O1Vhfvf3Oj+J2Y6uDIdmZkO60zXBKYLCtvViOlSxnAd/AL9N+JP2K1NkAAAAABJRU5ErkJggg==);
  }

  .baggage-row {
    display: flex;
    padding-right: 40px;
    font-family: "open sans", Arial, serif;
    font-size: 15px;
    font-weight: 600;
    margin-top: 6px;

    &:first-child {
      margin-top: 0;
    }

    &.header {
      color:#b3b3b3;
      font-size: 14px;
      font-weight: bold;
      padding-right: 45px;
    }

    .b-num {
      margin-left: 10px;
      width: 15px;
    }
    .b-name {
      margin-left: 50px;
      width: 150px;
    }

    .b-type {
      width: 250px;
    }
  }
}

