@import 'app/styles/helpers';


.seats-taken {
  width: 250px;
  position: absolute;
  text-align: center;
  font-size: 16px;
  left: 50%;
  top: 50%;
  //transform: translate(-50%, -50%) translateY(-10px);
  color: #ff0000;
  font-weight: 700;
  opacity: 0;
  transition: opacity 0.3s ease-in-out, transform 0.3s ease-in-out;
}
.hide-seats-taken {
  opacity: 1;
  transform: translate(50%, -50%) translateY(0);
}

.cs-popup {
  position: fixed;
  width: 100%;
  height: 100%;
  visibility: hidden;
  opacity: 0;
  left:0;
  top:0;

  @include transition(all, 0.5s , ease);

  .cs-popup-bg {
    position: fixed;
    width: 100%;
    height: 100%;
    left:0;
    top:0;
    background-color: rgba(255, 255, 255, 0.85);
  }

  &.open {
    visibility: visible;
    opacity: 1;

    .cs-popup-wrap {
      right: 0;
    }
  }

  .cs-popup-wrap {
    position: absolute;
    box-sizing: border-box;
    width: 1000px;
    height: 100%;
    right: -1000px;
    background: #FFFFFF;
    box-shadow: 0 0 11px 0 rgba(0, 0, 0, 0.19), 0 0 6px 0 rgba(0, 0, 0, 0.26);
    padding: 30px 40px 10px 30px;
    @include transition(right, 0.5s , ease);
    overflow-y: auto;
  }

  .cs-popup-close {
    position: absolute;
    left:30px;
    top:15px;
    color:#9b9b9b;
    cursor: pointer;
    opacity: 1;

    @include transition(opacity, 0.5s , ease);

    &:hover {
      opacity: 0.5;
    }

    &:after {

      position: absolute;
      font-size: 40px;
      content: "\D7";
      font-weight: normal;
      font-family: serif;
      display: block;
      top:0;
      left:0;
    }
  }

  .sticky-footer {
    height: 82px;
    width: 1000px;
    position: absolute;
    bottom: 0;
    background: #FFF;
    right: 0;
    box-shadow: 0 0 11px 0 rgba(0, 0, 0, 0.19), 0 0 6px 0 rgba(0, 0, 0, 0.26);
    z-index: 1;

    >.wrap {
      position: absolute;
      height: 100%;
      width: 100%;
      padding: 12px 20px 12px 20px;
      box-sizing: border-box;
    }

    .btn.btn-regular {
      display: inline-block;
      padding-right: 55px;
      padding-left: 25px;
      position: relative;

      .icon-back {
        position: absolute;
        top:21px;
        right: 18px;
      }
    }
  }

  .sub-wrap {
    overflow-y: auto;
    position: absolute;
    box-sizing: border-box;
    width: 960px;
    height: 100%;
    top: 0;
    padding-bottom: 85px;
    padding-top: 30px;
  }

  .popup-loader {
    position: relative;
    top: 300px;
  }
}

.cs-popup {
  .dlg-body {
    .dlg-title {
      font-size: 35px;
      font-weight: bold;
      display: flex;
      align-items: center;
      padding-bottom: 26px;
      margin-bottom: 26px;
      border-bottom:1px solid #eeeeee;

      .icon-flight {
        width: 25px;
        height: 26px;
        background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADYAAAA0CAMAAAD2UzvkAAAA51BMVEUAAACAgIBVVVWAgIBVVVVdXV1OTk5VVVVHR0dNTU1JSUlOTk5OTk5MTExJSUlHR0dGRkZLS0tJSUlKSkpISEhGRkZJSUlISEhKSkpHR0dISEhISEhGRkZISEhHR0dISEhHR0dISEhHR0dGRkZISEhISEhHR0dHR0dGRkZHR0dHR0dHR0dGRkZHR0dGRkZHR0dGRkZHR0dGRkZHR0dHR0dGRkZGRkZHR0dGRkZHR0dGRkZHR0dHR0dGRkZHR0dHR0dGRkZHR0dGRkZGRkZHR0dHR0dHR0dGRkZHR0dHR0dHR0dGRkZGRkZDY7BwAAAATHRSTlMAAgMECQsNDxIUFRcaGxwkKCkxNDU6Ozw+SE5RV1heX2FjZG1udXeEipSam5ydoKanqquws7nAxszN0tnb3ebn6ers7/Hy9ff4+f3+jEMALwAAASpJREFUeNq11uWSsjEMBeCDu/Ppursr7n7u/3qW8srgkOzs87dTb5PgZ6R6HPsLoTfTq+mFzB6Ne8iEajR2IHNFoxeASG5I4xUi3gInjiFyyolhDBLxDic+IPJEyzkk/tGWhoC/YvcqQeKCtksIZAa0/YbAO211j6DXAR23mJPL53NYLtxwu/3HrBOSn1juxu3V9WNGtk/yAkv9GrndXjAjWObYHyzjK9J1iBkPHGt5scwZXcMIph3ReIYRv690ZgzpGi1rOTK9kk0KJUy3RwoVYVQpdPmdbg/yRSqPRHsBG687uvK69Y9L/5RnXCs+jv6bYt/tdicIClMhqOGBQFoe8PThVR3MlalDm6gQb8vToj4JK1K+vsDQlzMIVVXFE3ZFpZq8MJSXoQJfCHvr6Yc8DYwAAAAASUVORK5CYII=);
        background-size: 25px 26px;
        margin-left: 11px;
      }
      .little-text {
        font-size: 15px;
        flex-basis: 100%;
        margin-top: 26px;
      }
    }
    .wrap {
      flex-wrap: wrap;
    }

    .dlg-section {
      border-top:1px solid #eeeeee;
      margin-top: 26px;
      padding-top: 26px;
      &:first-child {
        margin-top: 0;
        padding-top: 0;
        border:none;
      }
    }

    .icon-print {
      background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACgAAAAkCAMAAADM4ogkAAAAJFBMVEUAAAD///+jo6OkpKSenp6bm5ucnJycnJybm5ucnJybm5ubm5stAaMrAAAAC3RSTlMAARkcHWa2t73I83g6FPIAAABgSURBVHja7c2BBsUwDIXhk97krkne/30XJlC6FEPNPjjwc5B8AukL6/BhJGp+w1QIoXUv9QZQdLVOEF8i0KL4Mx8xCivCH8AxBi/wFTp84TrD0k4hBu8N0yxE2iQcbRKe1Jonbr5cMNMAAAAASUVORK5CYII=);
      width: 20px;
      height: 18px;
      background-size: 20px 18px;
      position: absolute;
      left:79px;
      top:32px;
      cursor: pointer;
      opacity: 1;
      @include transition(opacity, 0.5s , ease);

      &:hover {
        opacity: 0.5;
      }
    }

    .dlg-block {
      margin-right: 45px;
      position: relative;
      padding: 20px 0 20px 0;

      border-top:1px solid #eeeeee;
      margin-top: 20px;
      &:first-child {
        margin-top: 0;
        border:none;
      }
    }

    .dlg-checkin-header {
      font-size: 24px;
      font-weight: 700;
      margin: 40px 0 7px;
      display: inline-block;
    }

    .dlg-payment-header-warning {
      font-weight: bold;
      color: red;
      margin-right: 5px;
    }

  }
}


// MOBILE
@media only screen and (max-width: 1024px) {
  .cs-popup {
    .cs-popup-wrap {
      width: 100%;
    }
  }
}