.dlg-add-seats {
  width: 418px;
  height: 418px;
  padding: 35px 29px 26px;
  border-radius: 4px;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16), 0 3px 6px 0 rgba(0, 0, 0, 0.23);
  background-color: #ffffff;
  margin: auto;
  box-sizing: border-box;
  >.wrap {
    position: relative;

    .header-image {
      width: 174px;
      height: 158px;
      background: url('~app/desktop/images/seating-a-1@2x.png') no-repeat top right;
      background-size: 174px 158px;
      margin: auto;
    }

    .text {
      margin-top: 35px;
      font-size:22px;
      line-height: 1.14;

    }

    .buttons {
      display: flex;
      margin-top: 44px;

      .btn-1,
      .btn-2 {
        width: 195px;
      }

      .btn-2 {
        margin-right: 15px;
      }
    }

  }
}