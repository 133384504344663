@import 'app/styles/theme';

.clock-wrap {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  width: 19px;
  height: 19px;
  min-width: 19px;
  border-radius: 50%;
  //border: solid 3px #464646;
  border: solid 3px #155c80;
  align-self: center;

  &.checkedIn {
    border: solid 3px #464646;
  }

  .clock-hour {
    position: absolute;
    bottom: 47%;
    left: 8px;
    width: 2px;
    height: 6px;
    //background-color: black;
    background-color: #155c80;
    transform: rotate(-180deg);

    &.checkedIn {
      background-color: black;
    }
  };

  .clock-minute {
    position: absolute;
    bottom: 17%;
    width: 2px;
    left: 53%;
    height: 7px;
    //background-color: black;
    background-color: #155c80;
    transform: rotate(323deg);

    &.checkedIn {
      background-color: black;
    }
  };

  .checkin-mark {
    width: 16px;
    left: 10px;
    bottom: 10px;
    height: 16px;
    position: absolute;
    //background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACQAAAAkCAMAAADW3miqAAAABGdBTUEAALGPC/xhBQAAAAFzUkdCAK7OHOkAAACHUExURUdwTP///////////////////////////////////////////////////////////////////////////////////////////0ZGRvr6+lxcXOrq6qysrFBQUJWVlXt7e93d3by8vEtLS2ZmZqOjo/T09G5ubsvLy7W1tYqKitLS0uTk5MXFxdw1M30AAAAXdFJOUwAE83cVb1X7C8eGlV06sdcwouVmRrohw/eEqAAAAb9JREFUOMuVVNmCgjAMrCuiKIfntgXkPkT8/+/baQChgg87D0pKTCaTsYxp2DnmZr8xnR37BuNy2/IOP669mLdb//ApzpfVLOd6plfBMw3TZ0DP3vEjZ6MaBUUuOlRhiXhrajkHHJVFJkbcQ4mz9bQO4sQXOnLVdKx1Ra/6Lj5xTzDmwGsHzsk8Rwgftbx+xj34+GIJFXhdOg2hTySWEUIvUnXNeZvN+EQxfUEJWyVZC4X8oJRFX+qkumHQ2fQlyDyJFQYEdROjffJVckvqJ/DokJCpnhMrrWXTBU8SFAKEPZF8khP3v0g53zB2GnhHUqqSDXLkO0cx39NuaQ7R4mWdRapOWb17p7RlyPSg8KFKtOqjzEeCNQllY7kUZi9JXpPBVBHUvzJ2xHEveEqU22mOjxMDasIDA00YTep+iDi3elfW77PkoXsm6d3poGL1xQUNjP5LXnHnixm8ANqHznSg3qvwiRfWa7C3NRdtV3DaSYfVjQ+yT5A9eGem4RbwENe6q3Lsn1vTv7phKRnDMS1PlfiufmusTrSSpGjiKm7ClqL9/MbwuA7LWbp8bGvM2N7M79fYwbXOlnuwDfZf/AGuWVnrGQivSwAAAABJRU5ErkJggg==) no-repeat;
    background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAMAAAC6V+0/AAAABGdBTUEAALGPC/xhBQAAAAFzUkdCAK7OHOkAAAB+UExURUdwTPv8/f///9Hf5v7//////+7z9////+Xt8v///9rl6////9Th6P////P3+f///////////////87e5v////7+/v///////////////////////////////////8zc5P///xhegbbN2EN8mb3S3KK/zk6Fn2iWrjBvj16QqDcTHJ4AAAAfdFJOUwDAYe7S7r/2xOjVC+RnwN6kQjf2E8basVQmg46ZfiATo204AAAA2UlEQVQY002Q2bLCIBBEJwlrzL64XPXSGJPo//+gIJHKeaDg1FTX0ERfuivjsj6lB4pcEmzo8udaDi7yrC8UA5ow3EkkmQkoica7g0ZlIrmET/gDMzsUtAvgyH/iObuDIaUWx+gWu/rRE5Ux8THZ18OYArWLFE68V+8m50wPSfevtHad7OKdycApReJu88va5RmWAqMLuL/O0zs4I3AjYlD7PXuNlugMXexkhdH/MwHLohOQnZfDEXpLKFyHaahuGIG6UkqMEvIcWy71VnLzTzva+625pkN4fABmdCA67PvwBAAAAABJRU5ErkJggg==) no-repeat;
    background-size: 100%;
  }

  .checkin-mark-green {
    background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABIAAAASCAYAAABWzo5XAAAABmJLR0QA/wD/AP+gvaeTAAACvElEQVQ4jY2UbWjNYRjGf/fzd7YzZ2OxszCb97VCXicjSigjbfhE+SIlWhJFjIjJF8LIB+WLorwmGinyMsRm3iNvw8yOZsPmzM5xzv+5fbANcw6uj899X7/u7q77EWJIVT3AVCAfGAAkAe+Bm8ApEXkXy9cZUuDaaLWqasSG3drg82h18wO3KdwQVVW1aiOqWqqqKb/6pBNkC1Bc1/JSD78oMbfqywi7XzvqA1KGU9C/iCkZCxT0iWDyRaTmN5CqrgB2Xqk7yp5Hy/hmQ3GnHuufwZqRB22C430imPEiEpQ2yBBFH99tuOhsrponVt1/rmBc+kyKRx9RQXaJyErT9r7e1YjZ+6jorxBB8HszAaioP8vVwDGxaotUtZdR1QTFzr0WOGkaQrVxIV7HR8m4MnZNvMHotGkAnHpVihHjAQoNMFQwyXcaLsSF+DzdKcktY1iPSTSG3vG0qRKA6ub7BCOfXSDPAL0BGkM/ozErawnzB69FEFIT0tmae44hqWN42XyP4oqZtESaAFCUD6G3AvTpAoQAPCaxAzSt70Iyk3MY1G0UvbsOpK8vm6efKthYVUBrNPjbtIkmSYFWA9QAZPiyO4qlD5fRGKojNz2fDN9gHn4sZ33lrD8gCU4S/qRMAWqMiLyw6r6e0KtQ2xtefXnApqo5PPtcSXngBBtvF8bM1Zi06XhMogHOt+doHbB1Q+Vs7jdeirv0X+VIF3ZOuG6zknPeG3EGtudot1W3btWIA25Pb5//Ai3K2Ub/lKHGiLNaRMIGQERajDgF3T1p0e15l93s1Ny4AK/jY/mwfczutxRgv4gcgj+PdrxV97Rg/Dfrz1AeOM6b4GPCbit+bwYj06aSn7XY7ebp6QA7gDUiEvsUVLWHqm636jZpJ1lrbdRGy1V1cmefxIK1AT1AHj8+tmSgFqgQkUCs/u+8dj3a1s8nRQAAAABJRU5ErkJggg==) no-repeat;
  }
};

.clock-header {
  margin-left: 10px;
}

.checkin-wrapper {
  width: 291px;
  height: 57px;
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.36);
  border-radius: 8px;
  padding: 12px 12px 12px;
  box-sizing: border-box;
  margin: 13px 0;
  display: flex;
  flex-direction: row;
}

.chk-enabled-arrow {
  display: flex;
  padding-right: 12px;
  width: 38px;
  border-right: solid 1px #f0f0f0;
  margin-right: auto;
  align-self: center;
  height: 57px;
  align-items: center;
  justify-content: center;

  .arrow-left {
    @if ($theme == "bluebird") {
      background-image: url('~app/desktop/images/bluebird/arrow-left.svg');
    } @else {
      background-image: url('~app/desktop/images/arrow-left.svg');
    }
    
    width: 16px;
    height: 16px;
    display: inline-block;
    cursor: pointer;
  }

}

.chk-finished-wrapper {
  border: none;
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.36);
}


.chk-content-wrap {
  padding: 0 4px 0 0;
  display: flex;
  flex-direction: column;
  justify-content: center;

  .chk-title {
    font-size: 18px;
    line-height: 16px;
    font-weight: bold;
    white-space: nowrap;
    padding-bottom: 2px;

    &.title-disabled {
      color: #155c80;
    }
  }

  .title-done {
    font-size: 16px;
  }

  .enabled-check-in {
    font-size: 18px;
    font-weight: bold;
    align-self: center;
    cursor: pointer;
  }

  .chk-text {
    color: #155c80;
    font-size: 14px;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;

    .chk-link {
      padding-left: 10px;
      cursor: pointer;
    }

    .chk-open {
      font-weight: bold;
      font-size: 18px;
    }
  }
}