.login-page {
  background: url('~app/desktop/images/top-login.jpg') no-repeat top center;
  background-size: 100%;
  //background-image: linear-gradient(83deg, #065a83 2%, rgba(1, 82, 122, 0) 55%), radial-gradient(circle at 49% 18%, #ff8103, rgba(255, 129, 3, 0) 35%), linear-gradient(95deg, rgba(1, 82, 122, 0) 38%, #a9e2ff 100%);

  flex-direction: column;
  display: flex;
  height: 100%;
  width: 100%;
  position: absolute;
  align-items: center;
  justify-content: center;


  .header-row {
    height: 100px;
    position: fixed;
    top:40px;

    .container {
      width: 1000px;
      margin: auto;
      position: relative;
      display: flex;
      justify-content: left;
    }
  }



  .login-form {


    width: 200px;
    margin: auto;
    flex-direction: column;

    display: flex;
    align-items: center;
    justify-content: center;

    >.wrap {
      width: 200px;
    }
  }
  .row {
    margin-top: 10px;
    min-height: 35px;
  }
}