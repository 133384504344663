@import 'app/styles/helpers';
@import 'app/styles/theme';

.btn {
  &.continue {
    @include prevent-selection();
    height: 57px;
    border-radius: 4px;
    background-color: $mainButtonBg;
    color: #FFFFFF;
    text-align: center;
    font-size: 20px;
    font-weight: bold;
    line-height: 57px;
    cursor: pointer;
    @include transition(background, 0.5s, ease);
    position: relative;
    padding: 0 16px 0 16px;
    display: flex;

    &.disabled {
      opacity: 0.3;
      cursor: default;
    }

    &:hover {
      background-color: $mainButtonBgHover;
    }

    .icon-arrow-next {
      position: absolute;
      left:16px;
      top:21px;
    }
  }
}