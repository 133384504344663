@import 'app/styles/helpers';

.dlg-extra-services {
  padding-left: 30px;

  .cs-popup-close {
    padding: 0;
  }

  h3 {
    font-size: 24px;
    margin-top: 12px;
    margin-bottom: 36px;
  }

  .cs-bluebird-vip-logo {
    margin-left: 14px;
  }

  .dlg-content {
    height: 100%;
    overflow-y: auto;
  }

  &__note {
    padding-top: 20px;
    padding-top: 32px !important;
    margin-bottom: 20px;
    margin-top: 80px !important;
    box-sizing: border-box;
    position: relative;

    .cs-info-icon {
      position: absolute;
      top: -30px;
      right: 26px;
    }

    p {
      font-size: 14px;
      line-height: 1.79;
      margin-top: 10px;
      padding-right: 24px;
      width: 650px;

      .strong {
        font-weight: 600;
      }
    }
  }
}