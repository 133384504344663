.dlg-add-baggage {
  width: 463px;
  height: 418px;
  padding: 35px 29px 26px;
  border-radius: 4px;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16), 0 3px 6px 0 rgba(0, 0, 0, 0.23);
  background-color: #ffffff;
  margin: auto;
  box-sizing: border-box;
  >.wrap {
    position: relative;

    .header-image {
      width: 265px;
      height: 156px;
      background: url('~app/desktop/images/baggage-a-1@2x.png') no-repeat top right;
      background-size: 265px 156px;
      margin: auto;
    }

    .text {
      margin-top: 35px;
      font-size:22px;
      line-height: 1.14;

    }

    .buttons {
      display: flex;
      margin-top: 44px;

      .btn-1,
      .btn-2 {
        width: 195px;
      }

      .btn-2 {
        margin-right: 15px;
      }
    }

  }
}

.add-baggage-wrap {
  .mw-button1 {
    display: flex !important;
    flex-direction: row !important;
    align-content: center !important;
    align-items: center !important;
    gap: 11px !important;
  }
}