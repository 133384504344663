.mw-modal {
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.7);
  z-index: 1000;

  .mw-content {
    box-sizing: border-box;
    width: 613px;
    height: 412px;
    background-color: #fff;
    padding: 23px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    border-radius: 4px;
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16), 0 3px 6px 0 rgba(0, 0, 0, 0.23);
  }

  .mw-section {
    font-size: 22px;
    line-height: 25px;
    padding: 0 80px;
  }

  .mw-imageWrapper {
    max-width: 265px;
    max-height: 155px;
    padding-top: 15px;
  }

  .mw-image {
    width: 100%;
  }

  .mw-button {
    cursor: pointer;
    border-radius: 4px;
    height: 46px;
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.2);
    padding: 14px 40px;
    font-size: 16px;
    font-weight: bold;
    transition: all 0.5s ease;
    color: #2076a2;
  }

  .mw-button:hover {
    background-color: rgba(1, 82, 122, 0.1);
  }

  .mw-button-wrapper {
    display: flex;
    flex-direction: row;
    gap: 20px;
    padding: 0 20px;
  }

  .mw-button1 {
    background-color: #ebf8ff;
  }

  .mw-button2 {
    background-color: #ffffff;
  }

  .symbol {
    font-size: 26px;
  }
}