@import 'app/styles/helpers';

.plane {
  direction: ltr;



  background: #f3f3f3;
  >.wrap {
    width: 512px;
    height: 1632px;
    background: url("~app/desktop/images/aircraft-big.png") no-repeat top left;
    background-size: 512px 1632px;
  }

  &.B733 {
   >.wrap {
     width: 512px;
     height: 1389px;
     background: url("~app/desktop/images/aircraft-small.png") no-repeat top left;
     background-size: 512px 1389px;
   }
  }

  .seats-map {
    padding-top: 118px;
  }

  &.B733 {
    .seats-map {
      padding-top: 144px;
    }
  }

  .seat {
    width: 35px;
    height: 35px;
    cursor: pointer;
    background: #FFFFFF;
    border-top-right-radius: 12px;
    border-top-left-radius: 12px;
    box-sizing: border-box;
    padding: 4px;
    position: relative;

    .tooltip {
      position: absolute;
      width: 109px;
      height: 41px;
      opacity: 0;
      background: #3d3d3d;
      visibility: hidden;
      color: #FFFFFF;
      line-height: 41px;
      text-align: center;
      top:-41px;
      left:-35px;
      z-index: 1;
      direction: rtl;
      font-size:14px;
      display: flex;
      align-items: center;
      justify-items: center;
      justify-content: center;
      @include transition(all, 0.3s , ease);

      .seat-num {
        font-family: "Open Sans", Arial, serif;
        font-size:12px;
      }

      .price {
        font-family: "Open Sans", Arial, serif;
        font-size:13px;
      }
    }

    @include transition(all, 0.3s , ease);

    .seat-icon {
      .txt {
        font-family: "open sans", Arial, serif;
        font-size: 12px;
        font-weight: bold;
        line-height: 27px;
        color:#FFF;
        text-align: center;
        opacity: 0;
        @include transition(all, 0.3s , ease);
      }
    }

    &.selected {
      .seat-icon {
        .txt {
         color: #ff8103;
          opacity: 1;
          line-height: 23px;
        }
      }
    }

    &:hover {
      .tooltip {
        opacity: 0.9;
        visibility: visible;
      }
      .seat-icon {
        .txt {

        }
      }
    }

    &.type-a:hover {
      background: #DCE9F2;

    }

    &.type-b:hover {
      background: #E2DCEA;
    }

    &.type-c:hover {
      background: #D5E2D6;
    }

    &.disabled {
      cursor: default;
      &:hover {
        background: #FFFFFF;

        .seat-icon {
          .txt {
            opacity: 0;
          }
        }
      }
    }

  }

  .row {
    display: flex;
    width: 290px;
    margin: 7px auto 0 auto;

    &:first-child {
      margin-top: 5px;
    }

    &.extend {
      //margin-top: 26px;
    }

    &.legend {
      margin-top: 0;
      .col {
        font-family: "open sans", Arial, serif;
        font-size: 16px;
        font-weight: 600;
        color: #cccccc;
        text-align: center;
        width: 50px;

        &.c-rnum {
          line-height: normal;
        }
      }

    }

    .col {
      margin-left: 5px;
      text-align: center;
      color: #cccccc;
      flex:none;

      &:first-child {
        margin-left: 0;
      }

      &.c-seat {
        width: 35px;
      }

      &.c-rnum {
        font-family: "open sans", Arial, serif;
        font-size: 16px;
        font-weight: 600;
        color: #cccccc;
        text-align: center;
        width: 50px;
        line-height: 35px;

      }
    }
  }
}