@import "app/styles/fonts.scss";
@import 'app/styles/helpers';
@import 'app/styles/theme';

html {
  direction: rtl;
  text-align: right;
  content: $theme;
}

input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type=number] {
  -moz-appearance: textfield;
}

html, body
{
  width: 100%;
  height: 100%;

  margin: 0;
  padding: 0;
}

#root {
  width: 100%;
  height: 100%;

  display: flex;
  flex-direction: column;

  justify-content: flex-start; /* align items in Main Axis */
  align-items: stretch; /* align items in Cross Axis */
  align-content: stretch; /* Extra space in Cross Axis */
}

a {
  color: $linkColor;
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
}

body {
  font-family: "almoni-dl", "Arial", serif;
  direction: rtl;
  text-align: right;
  color:#404042;
}

button {
  border:0;
  box-shadow: none;
  font-family: "almoni-dl", "Arial", serif;

}

.error {
  color:#FF0000;
}

.success {
  color:#228b22;
}

.preloader {
  width: 32px;
  height: 32px;
  background-image: url('../images/preloader.gif');
  margin: auto;
}

.cs-input {
  box-sizing: border-box;
  border: solid 1px #c6c9ce;
  border-radius:4px;
  height: 35px;
  line-height: 35px;
  padding: 0 10px;
  width: 100%;
}

.cs-button {
  box-sizing: border-box;
  height: 35px;
  line-height: 35px;
  padding: 0 10px;
  text-align: center;
  background-color: #2079a6;
  color:#FFFFFF;
  width: 100%;
  border-radius: 3px;
  cursor: pointer;
}

.cs-grid {
  width: 100%;
  height: 100%;
  position: absolute;
  background: url('~app/desktop/images/bg-ilustration.png') no-repeat top right;
}

.grid-container {
  width: 948px;
}

.cs-content {
  margin-right: 196px;
  padding-top: 5px;
  width: 948px;

  .cs-header {
    position: relative;
    color:#01527a;
    height: 175px;

    .login-info {
      float:left;
      height: 46px;
      border-radius: 23px;
      background: rgba(255,255,255,0.28);
      padding: 0 42px 0 16px;
      position: relative;

      .user-icon {
        background: url('~app/desktop/images/icons/user-icon@2x.png') no-repeat top left;
        width: 39px;
        height: 35px;
        background-size: 39px 35px;
        top:5px;
        right: -1px;
        position: absolute;
      }

      .r1 {
        font-size:17px;
        margin-top: 5px;
      }

      .r2 {
        font-size:14px;
      }

      .r1,
      .r2 {
        font-weight: 700;
      }
    }

    .cs-logo {
      background: url('~app/desktop/images/kavei-icon@2x.png') no-repeat top left;
      width: 166px;
      height: 73px;
      background-size: 166px 73px;
    }

    .txt,
    .phone {
      position: absolute;
      font-weight: 700;
      margin-right: 200px;
    }

    .txt {
      font-size:15px;
      top:34px;
    }

    .phone {
      font-family: "Open Sans", Arial, serif;
      font-size:28px;
      top:60px;
      line-height: 0.57;
    }
  }

  .main-title {
    font-size:45px;
    margin-bottom: 25px;
  }

  .cs-main-grid {

    .row {
      margin-top: 18px;
      &:first-child {
        margin-top: 0;
      }
    }

    .col-1 {
      width: 669px;

    }
    .row {
      display: flex;

      .col-1 {
        width: 669px;
        display: flex;
        margin-left: 18px;
      }

      .col-2 {
        flex: 1;
        display: flex;
        flex-direction: column;
      }

      .col-3 {
        width: 465px;
        margin-left: 18px;

      }

      .col-4 {
        flex: 1;
        display: flex;
      }
    }
  }
}

.ScrollbarsCustom {
  position: relative;
  width: 100%;
  height: 100%;
  .ScrollbarsCustom-Wrapper {
    position: absolute;
    inset: 0px 0px 0px 16px;
    overflow: hidden;
  }

  .ScrollbarsCustom-Track {
    position: absolute;
    overflow: hidden;
    border-radius: 4px;
    background: rgba(203, 203, 203, 0.26);
    user-select: none;
    width: 6px;
    height: calc(100% - 20px);
    top: 10px;
    left: 0;


    .ScrollbarsCustom-ThumbY {
      width: 6px;
      background: #464646;
      border-radius: 4px;
    }
  }

  .ScrollbarsCustom-Content {
  }
}

/*
.cs-footer {
  margin: 28px 0 28px 0;

  display: flex;

  .txt {
    font-size:12px;
    width: 430px;
    flex:auto;
  }

  .copyright {
    font-family: "Open Sans", "Arial", serif;
    font-size:14px;
    flex:auto;
    text-align: left;
  }
}

 */



.num {
  font-family: "Open Sans", "Arial", serif;
}

.heb {
  font-family: "almoni-dl", Arial, serif;
}

.large-title {
  font-size: 40px;
  font-weight: 900;
  margin-bottom: 15px;
  display: flex;
}


  .generic-tooltip {
    visibility: hidden;
    opacity: 0;
    position: absolute;
    z-index: 1;
    top:-50px;
    left:0;
    @include transition(all, 0.5s, ease);


    .text {
      background: #3d3d3d;
      height: 41px;
      color: #FFFFFF;
      white-space: nowrap;

      padding: 0 10px;
      line-height: 41px;

      left: calc(-50% + 11px);
    }

  }

  .ui-loader {
    margin: auto;
    width: 200px;
    height: 200px;
    background-image: url('../images/loader.gif');
  }

.p-num {
  width: 32px;
  height: 32px;
  border-radius: 50%;
  background-color: #f3f3f3;
  color:#464646;
  font-size:18px;
  font-weight: 700;
  text-align: center;
  line-height: 32px;
  font-family: "Open Sans", "Arial", serif;
}

.chk-passengers {
  padding-top: 25px;
}

.baggage-warn {
  color: #FF0000;
  font-size:16px;
}