.dlg-hotel-details {
  .large-title {
    margin-bottom: 0;
  }

  .main-status {
    margin-top: 8px;
  }

  .hotel-stars {
    display: inline-block;
    direction: ltr;
    >.wrap {
      display: flex;
      margin-top: 1px;
      margin-right: 0px;
    }
  }

  .row {
    display: flex;
    &.no-flex {
      display: block;
    }
    &.room-header {
      font-size:24px;
      font-weight: 700;
      margin-bottom: 16px;

      span {
        font-size: 18px;
        font-family: "Open Sans", Arial, serif;
      }
    }

    .col-1 {
      width: 350px;
    }

    .col-2 {
      width:500px;
    }
    .col-3 {
      padding-top: 14px;
      direction: rtl;
      font-size: 18px;
      line-height:1.06;
      color: rgba(0, 0, 0, 0.5);

    }

    &.header {
      color: #b3b3b3;
      font-size:14px;
      margin-bottom: 8px;

      &.names {
        margin-top: 30px;
        display: block;
      }
    }

    .p-name {
      flex:none;
      font-family: "Open Sans", Arial, serif;
      font-size:16px;
    }
    .col-board-base {
      width: 100px;
    }
    .col-type {
      width: 300px;
    }
  }

  .hotel-dates {
    font-size: 18px;
    font-weight: 700;
    .date {
      font-family: "open sans", Arial, serif;
      font-size: 15px;
    }
  }

  .icon-hotel,
  .icon-marker{
    position: absolute;
    top: 17px;
    right: -45px;
  }
}