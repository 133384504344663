@mixin transition($type: all, $time: 0.5s, $animation: ease) {
  -webkit-transition: $type $time $animation;
  -moz-transition: $type $time $animation;
  -o-transition: $type $time $animation;
  transition: $type $time $animation;
}


@mixin prevent-selection()
{
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

@mixin ellipsis() {
  display: block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}


@function strip-unit($number) {
  @if type-of($number) == 'number' and not unitless($number) {
    @return $number / ($number * 0 + 1);
  }

  @return $number;
}

@function get_vw_from_px($size_px) {
  @return 100 / 320 * strip-unit($size_px) * 1vmin;
}

@mixin icon_size_scalable($width, $height) {
  width: get_vw_from_px($width);
  height: get_vw_from_px($height);
  background-size: get_vw_from_px($width) get_vw_from_px($height);
}

@mixin english_font() {
  font-family: "Open Sans", Arial, serif;
}

@mixin hebrew_font() {
  font-family: "almoni-dl", "Arial", serif;
}