@import 'app/styles/helpers';

.dropdown-with-label {
    position: relative;
    @include prevent-selection();
    color:#404042;

    .wrap {
        border-radius: 4.5px;
        border: solid 1.5px rgba(151, 151, 151, 0.5);
        height: 32px;
        box-sizing: border-box;
        position: relative;
        cursor: pointer;
        display: flex;
        overflow: hidden;

        &:hover {
            background: #f7f7f7;
        }

        .label {
            background-color: #f3f3f3;
            font-size: 14px;
            line-height: 32px;
            padding: 0 9px;
            box-sizing: border-box;
        }

        .text {
            font-size: 14px;
            line-height: 32px;
            padding: 0 10px 0 25px;
            @include ellipsis();
            color: #404042;
        }

        .icon {
            position: absolute;
            left: 9px;
            top: 10px;
            width: 13px;
            height: 8px;
            background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABwAAAASCAMAAAB/2U7WAAAAsVBMVEUAAACAgIBVVVWAgIBmZmZVVVVVVVVNTU1GRkZVVVVOTk5JSUlERERQUFBFRUVBQUZBQUVDQ0NCQkRAQENBQUNCQkRBQUNBQUNAQEJBQUNAQERBQUNAQERBQUJBQUNBQUJAQENBQUNBQUJBQUJAQENBQUNBQUJAQENAQENBQUNAQENAQENBQUJAQENAQENBQUJAQENBQUJAQENAQENBQUNAQENBQUJBQUNBQUNAQEJAQEJyhv26AAAAOnRSTlMAAgMEBQYJCgsMDQ4PEDAzSkxlZ3KEhomLjY+Rk6KkpqeoqaqrrK2ur7Cys7y+v8HCxMbHycrg+Pn+WXZxNgAAAJ5JREFUeNqEiQWCg0AUQz+6vnVH6qXuwr//wUpw58HMJHlUyb+QXmphqj/1lO3YTT81XsyGGHddZrvlRYsdzJjtYbhLBJQtyji0fdf9IcJuUKeS1wYot18KkNcY5q4dIl5/EAO7wrSUiUYIF7gIaYHRUjQ85++4gp1hfuA6fVEaacIex0/KIpoMDnA51nDc/oPyEXTeqQj5tq3Se9QBAANOGOMrgS3GAAAAAElFTkSuQmCC);
            background-size: 13px 8px;
        }
    }
    .mobile-wrap {
        height: 44px;
    }

    .options {
        position: absolute;
        top: 0;
        min-width: 100%;
        max-width: 400px;
        box-shadow: 0 8px 8px 0 rgba(0, 0, 0, 0.24), 0 0 8px 0 rgba(0, 0, 0, 0.12);
        border-radius: 4px;
        padding: 39px 0 16px 0;
        background-color: #fafafa;

        .options-wrap {
            background-color: #fafafa;
            border-radius: 4px;
            border-top-right-radius: 0;
            border-top-left-radius: 0;

            ul {
                list-style: none;
                margin: 0;
                padding: 0;
                max-height: 200px;
                overflow-y: auto;

                li {
                    height: 26px;
                    line-height: 26px;
                    padding: 12px 10px 12px 7px;
                    display: block;
                    font-size: 14px;
                    color: #404042;
                    cursor: pointer;
                    white-space: nowrap;
                    text-overflow: ellipsis;
                    overflow: hidden;

                    &:hover {
                        background-color: #d8d8d8;
                    }
                }
            }
        }
    }

    &.open {
        z-index: 1;

        .wrap {
            border-radius: 4px;
            border-bottom-right-radius: 0;
            border-bottom-left-radius: 0;
            border-color: transparent;
            border-bottom: solid 1px rgba(0, 0, 0, 0.12);
        }
        .icon {
            rotate: 180deg;
        }
    }

    &.disabled {

        .text,
        .icon,
        .label {
            opacity: 0.3;
        }

        .wrap {
            cursor: default;

            &:hover {
                background: none;
            }
        }
    }
}

// MOBILE
@media only screen and (max-width: 1024px) {
    .dropdown-with-label {
        .wrap {
            height: 42px;
            .text {
                font-size:16px;
                display: flex;
                align-items: center;
            }
            .icon {
                top:18px;
            }
        }

        .options {
            padding-top: 44px;

            .options-wrap {
                ul {
                    li {
                        box-sizing: content-box;
                        font-size:16px;
                    }
                }
            }
        }
    }
}