@import 'app/styles/helpers';

.checkin-footer {
  display: flex;
  justify-content: space-between;
  position: relative;

  &.disabled {
    opacity: 0.3;
    cursor: wait;
    pointer-events: none;
  }

  .col-1 {
    width: 120px;
    flex:0;

    opacity: 0;
    @include transition(all, 0.5s, ease);
    pointer-events: none;

    .btn.btn-regular.big {
      display: flex;
      align-items: center;
      padding-left: 26px;

    }

    .icon-back {
      margin-left: 16px;
    }
  }

  .col-1.visible-back {
    opacity: 1;
    pointer-events: initial;
  }

  .col-2 {
    flex:1;
  }

  .col-3 {
    width: 165px;

    .btn-regular {
      display: flex;
    }
  }
  .continue-wrapper {
    opacity: 1;
    @include transition(all, 0.5s, ease);
  }

  .continue-hidden {
    opacity: 0;
    pointer-events: none;
  }

  .continue-disabled {
    pointer-events: none;
    opacity: 0.5;
  }

  .slider-wrapper {
    display: flex;
    justify-content: flex-end;
    flex-direction: column;
    align-items: flex-end;
    flex: 1 1;
    margin-left: 20px;
    height: 57px;
    padding-top: 260px;
    @include transition(all, 0.5s, ease);

    .slider-content {
      height: 32px;
      padding: 10px 0;
      position: relative;
    }
  }

  .baggage {
    padding-top: 208px;
  }
  .seats {
    padding-top: 156px;
  }
  .payment {
    padding-top: 104px;
  }
  .iframe {
    padding-top: 52px;
  }
  .finished {
    padding-top: 0;
  }

  .validation-error-msg {
    font-size: 16px;
    color: #f50605;
  }
}