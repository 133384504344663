.cs-header-login {
  height: 46px;
  padding: 6px 6px 6px 15px;
  border-radius: 23px;
  background-color: #ffffff;
  min-width: 227px;
  //position: absolute;
  box-sizing: border-box;
  display: flex;

  flex:none;
  align-content: center;
  align-items: center;
  justify-content: center;
  margin-right: 21px;

  &:first-child {
    margin-right: 0;
  }

  &.agent {
    min-width: inherit;
    a {
      font-size: 16px;
      font-weight: 600;
    }
  }

  .account {
    flex:1 auto;
    font-family: "Open Sans", Arial, serif;
    font-size: 13px;

  }

  .icon {
    &.icon-account {
      background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAEgAAABGCAMAAABYOSIFAAABoVBMVEUAAAAA//8AgIAAVaoAgIAAZmYAZpkAVYAASW0AVY4ATYAAZoAAXXQAXYsAVYAATnYAW4AAVXcAUIAAWngAVYAAUXkAWYAAVXkAUYAATnoAWXoAVYAAUnoATnYAWIAAVXsAUoAAT3sAWHsAVXcAVYAAUnsAUHgAVXwAU3gAUHwAVXgAU3wAUXkATnYAVXwAU3kAUXYAUXwAU30AUXoAVX0AU3oAUXcAUHoAVXoAU3gAU30AUnoAUHgFUHgFTnYFTXgFUHYFUnsET3sEVHkEUncEUXkEUXwEUHoEUH4EUXoDU3kDUXkDUXsDUHkDUnwDU3oDUHgDUXgDUnsDUXcDUnwCUXoCUHoCUXsCUXoCUXoCUnkCU3kCUXoCUnoCU3sCUHsCUnkCUnkCUnoCU3sCUXkCUnsCU3sCUnwCUnoBUnkBUnoBU3oBUXkBUXoBU3kBUXoBUXoBUnoBUnoBUnoBUnoBU3sBUnkBUnkBU3sBUnoBUnkBUnoBUnoBU3oBU3sBUnoBUnsBUXsBUnoBU3oBU3oBU3oBUnoBU3oBU3sBUnoBUnrZV2bUAAAAinRSTlMAAQIDBAUFBgcJCgoLCwwNDg8QERITFBUWFxcYGRoaGxwdHR4eHyAhIiMkJSYnJygpKSssLS4vMDAxMTIzMzQ1Njg6PT4/QkNDRUpMT1BUVllbXV5ja3N0dXd6foSGh4+Sk5aXmp6goaWrrq+ztr/AwsPFxsfLztTY29/f4uPk5ufr7/Dx9/j8/f4WsQjAAAAChElEQVR42tXWg2IkSRzH8X+NEWMQ29bMXP9i3916k7WVNbJmjHrp1ah7UtWI83mAb6NIJ4w9K78oECwuyHUz2i2WU9bWj5RYd22hg6zLqY9gp7ZiO1nBAr2Q+KfWbT4T7IMOpd5Fpvi7YCAaYmSIVcKETg8ZcHfAlEgB6fL1w6xS0pEbhXnVTN5RYEU9SfiisKaChNz9sCpAAqwDlik+2qkSu9Brp0x+7EotZWCd2B0/aQUgMX594fWHxUfnIdFJGqwPYleX+V/bT6cglm/qhS7zlHemXqkXQpPLPO0axHIoLQdil7jKIsQaKK0eYne5yg+IxWyUxCIQe8ZVtkcgVkhJ2ZB4ztVGDXeBsr2FeimpdW8huCmhH2JDn7naRRjMSTskznCNBUiEjRb+7CZXuw2JOorLh8x9rvJpEhLNFFcEmaElnjYHmVbpik25x1O+D8JoKwlCanaVJ92AVLf801LmecJLyLVRXAF0vE+ETkGuheJyoeNtIvQv5Bopzg25Cxvy2ZhSRgkxSIzc2uBJr+TvVEQJ3RAauvKFq6w/nIaYjxJqIDB28yvPsP74fwjEGMmH7b8HK1xg68XcoGjQEhzQGp5/s8Vlvt2ZgVYJpbRB4wnXtToJDQ+lFEPjI9d3DmodlGaP7SEUIJXq3YcGbKTiVnYdKiWNegs/e20CaRE7abgiUDl9Vs84VIKUIYhd6Wb7c/dT/LSDOwLrQiRQAMuaSagUFnXbSawKlvQ6SYLVWup4SK4cpnU7SU9AgTnNdtLn7YEJSogM2athqMtPZvg6oSsSZGRSXgekBkrtZEFOfRQi7QEbWWQrrO+FRrQl7KbdceeFaptaOzvbmhvKCr2MTphfL1SycfaWWNsAAAAASUVORK5CYII=);
      width: 35px;
      height: 34px;
      background-size: 35px 34px;
      margin-left: 8px;
      flex:none;
    }

    &.icon-search {
      background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIzNSIgaGVpZ2h0PSIzNSIgdmlld0JveD0iMCAwIDM1IDM1Ij4gICAgPGcgZmlsbD0ibm9uZSIgZmlsbC1ydWxlPSJldmVub2RkIj4gICAgICAgIDxnIGZpbGw9IiMwMTUyN0EiPiAgICAgICAgICAgIDxnPiAgICAgICAgICAgICAgICA8Zz4gICAgICAgICAgICAgICAgICAgIDxnIHRyYW5zZm9ybT0idHJhbnNsYXRlKC02MDggLTQ1KSB0cmFuc2xhdGUoNDU4IDQwKSB0cmFuc2xhdGUoMTUwIDUuNjkzKSB0cmFuc2xhdGUoLjIwNSAuMzA3KSI+ICAgICAgICAgICAgICAgICAgICAgICAgPGNpcmNsZSBjeD0iMTYuNzczIiBjeT0iMTYuNTkzIiByPSIxNi4zMDgiIGZpbGwtb3BhY2l0eT0iLjIiLz4gICAgICAgICAgICAgICAgICAgICAgICA8cGF0aCBmaWxsLXJ1bGU9Im5vbnplcm8iIHN0cm9rZT0iIzAxNTI3QSIgZD0iTTIwLjQzNSAxOS4wNjNoLS43MjNsLS4yNTYtLjI0N2MuODk3LTEuMDQzIDEuNDM3LTIuMzk3IDEuNDM3LTMuODdDMjAuODkzIDExLjY2MiAxOC4yMyA5IDE0Ljk0NiA5IDExLjY2MiA5IDkgMTEuNjYyIDkgMTQuOTQ2czIuNjYyIDUuOTQ3IDUuOTQ2IDUuOTQ3YzEuNDczIDAgMi44MjctLjU0IDMuODctMS40MzdsLjI0Ny4yNTZ2LjcyM0wyMy42MzcgMjUgMjUgMjMuNjM3bC00LjU2NS00LjU3NHptLTUuNDg5IDBjLTIuMjc4IDAtNC4xMTYtMS44MzktNC4xMTYtNC4xMTdzMS44MzgtNC4xMTYgNC4xMTYtNC4xMTZjMi4yNzggMCA0LjExNyAxLjgzOCA0LjExNyA0LjExNiAwIDIuMjc4LTEuODM5IDQuMTE3LTQuMTE3IDQuMTE3eiIvPiAgICAgICAgICAgICAgICAgICAgPC9nPiAgICAgICAgICAgICAgICA8L2c+ICAgICAgICAgICAgPC9nPiAgICAgICAgPC9nPiAgICA8L2c+PC9zdmc+);
      width: 35px;
      height: 34px;
      background-size: 35px 34px;
      margin-left: 8px;
      flex:none;
    }
  }
}