@import 'app/styles/helpers';

.txt-field {
  border-radius: 4.5px;
  border: solid 1.5px #cacaca;
  height: 47px;
  padding: 0 17px;
  position: relative;

  @include transition(border-color, 0.5s, ease);

  &.with-tooltip {
    padding-left: 40px;
  }

  .field-tooltip {
    position: absolute;
    left:10px;
    top:13px;
    width: 23px;
    height: 23px;


    .tooltip {
      visibility: hidden;
      opacity: 0;
      position: absolute;
      z-index: 1;
      top:-50px;
      left:0;
      @include transition(all, 0.5s, ease);


      .text {
        position: relative;
        background: #3d3d3d;
        height: 41px;
        color: #FFFFFF;
        white-space: nowrap;

        padding: 0 10px;
        line-height: 41px;

        left: calc(-50% + 11px);
      }

    }

    &:hover {
      .tooltip {
        visibility: visible;
        opacity: 1;
      }

    }
  }

  &.error {
    border-color: #ff4f4f;

    input {
      color:#ff4f4f;
    }
  }

  &.disabled {
    opacity: 0.5;
  }

  input {
    @include transition(color, 0.5s, ease);

    width: 100%;
    border:0;
    padding: 0;
    margin: 0;
    text-align: right;
    font-size:18px;
    color:#464646;
    font-family: "almoni-dl", Arial, serif;
    height: 47px;
    background: none;
    &:focus { outline: none; }
  }

}