$theme: 'kavei';
$linkColor: #2079a6;
$mainButtonBg: #ff8103;
$mainButtonBgHover: #cb722a;
$stepBackgroundColor : #cddde4;
$stepActive: rgba(32,118,162,255);
$stepTextColor: #2076a2;
$priceColor: $mainButtonBg;
$pNumBgColor: $stepTextColor;

$mobileTopLogoPath: '~app/mobile/assets/images/top-logo.png';


@if (env("REACT_APP_THEME") == "bluebird") {
  $theme: 'bluebird';
  $linkColor: #164397;
  $mainButtonBg: #fdad0c;
  $mainButtonBgHover: #b37928;
  $stepBackgroundColor : #cee1f9;
  $stepActive: #164397;
  $stepTextColor: $linkColor;
  $priceColor: $mainButtonBg;
  $pNumBgColor: $stepTextColor;

  $mobileTopLogoPath: '~app/mobile/images/bluebird/top-logo.jpg';
}

:export {
    theme : $theme;
}