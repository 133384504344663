@import 'app/styles/helpers';

.txt-field-small {
  border-radius: 4.5px;
  border: solid 1.5px #cacaca;
  height: 26px;
  padding: 0 10px;


  &.eng {
    text-align: right;
    direction: ltr;
  }

  @include transition(border-color, 0.5s, ease);

  &.error {
    border-color: #ff4f4f;

    input {
      color:#ff4f4f;
    }
  }

  &.disabled {
    opacity: 0.5;
  }

  input {
    @include transition(color, 0.5s, ease);

    width: 100%;
    border:0;
    padding: 0;
    margin: 0;
    text-align: right;
    font-size:14px;
    color:#464646;
    font-family: "Open Sans", Arial, serif;
    height: 26px;
    background: none;
    &:focus { outline: none; }
  }

}