.dlg-payment-body {
  margin-top: 20px;

  .helper {
    transition: all 0.5s ease-in-out;
    padding: 16px;
    text-align: center;
    height: 20px;
  }

  .hidden {
    height: 20px;
    padding: 16px;
  }
}

.dlg-body {

  .dlg-checkin-header {
    font-size: 24px;
    font-weight: 700;
    margin: 40px 0 7px;
    display: inline-block;
  }

  .dlg-payment-header-warning {
    font-weight: bold;
    color: red;
    margin-right: 5px;
  }
}