@import 'app/styles/helpers';
@import 'app/styles/theme';

.dlg-seats {

  .web-check-space {
    padding-top: 17px;
  }

  .row {
    display: flex;
  }
  .col-1 {
    width: 385px;
  }

  .col-2 {
    max-width: 500px;
    margin-right: 30px;
  }

  .seat-icon {
    width: 27px;
    height: 27px;
    background: #ebebeb;
    border-top-right-radius: 8px;
    border-top-left-radius: 8px;
    box-sizing: border-box;




    &.type-a {
      background: #2076a2;
    }

    &.type-b {
      background: #705195;
    }

    &.type-c {
      background: #7fc783;
    }

    &.disabled {
      background: #ebebeb;
    }

    &.selected {
      border: 2.5px solid #ff8103;
      background: #FFFFFF;
    }

    &.legend {
      width: 21px;
      height: 21px;
    }
  }
}

.dlg-seats-all-paid {
  opacity: 0.4;
  pointer-events: none;
}

.col-btn-continue {
  display: flex;
  justify-content: flex-end;
  flex: 1;
  overflow: hidden;

  .price-col {
    display: flex;
    align-items: center;
    margin-left: 13px;

    .text {
      font-size: 13px;
      margin-left: 15px;
      text-align: left;
    }
    .price {
      font-family: "Open Sans", Arial, serif;
      font-size: 23px;
      font-weight: 700;
      color: $priceColor;
    }
  }

  .price-height {
    height: 32px;
  }
}

.col-btn-back {
  width: 155px;
  float:right;
}

.allPaidMsg {
  font-size: 16px;
  color: #ff4f4f;
  padding: 25px 0 39px 0;

}