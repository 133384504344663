.cs-info-icon {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  align-self: self-start;

  span {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -74%);
    font-family: "almoni-dl", "Arial", serif;
    font-weight: 900;
    font-style: normal;
    color: #2076a2;
    white-space: nowrap;
  }

  .cs-info-red-text {
    color: #ff8103;
  }
}