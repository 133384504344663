@import 'app/styles/helpers';

.dlg-seats-details {
  margin:auto;
  text-align: right;

  >.wrap {
    width: 430px;
    padding: 29px 32px 42px 29px;
    border-radius: 4px;
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16), 0 3px 6px 0 rgba(0, 0, 0, 0.23);
    background-color: #ffffff;
    box-sizing: border-box;

    display: flex;
    flex-direction: column;

    position: relative;

    .btn-popup-close {
      position: absolute;
      left:30px;
      top:15px;
      color:#9b9b9b;
      cursor: pointer;
      opacity: 1;

      @include transition(opacity, 0.5s , ease);

      &:hover {
        opacity: 0.5;
      }

      &:after {

        position: absolute;
        font-size: 40px;
        content: "\D7";
        font-weight: normal;
        font-family: serif;
        display: block;
        top:0;
        left:0;
      }
    }



    .row {
      display: flex;
      &.header {
        font-family: "almoni-dl", Arial, serif;
        font-size: 26px;
        font-weight: 700;
      }



      &.body {
        flex-direction: column;
        font-size: 14px;
        padding: 30px 0 30px 0;
        color: #464646;

        .row {
          padding: 8px 0 8px 0;
          align-items: baseline;


          &.table-header {
            font-family: "almoni-dl", "Arial", serif;
            font-weight: 700;
            font-size:14px;
            color: #b3b3b3;
            padding: 0;

            .col {

              box-sizing: border-box;

              &.name {
                padding-right: 17px;
              }

              &.price {
                font-family: "almoni-dl", "Arial", serif;
                font-weight: 700;
                font-size:14px;
              }
            }
          }

          .col {
            flex: 1;
            &.count {
              .num {
                font-family: "Open Sans", Arial, serif;
                font-size: 12px;
                font-weight: 600;
              }
            }

            &.name {
              font-family: "Open Sans", Arial, serif;
              font-size: 13px;
              flex: none;
              width: 150px;

              .num {
                font-size: 16px;
                font-weight: 700;
              }
            }

            &.dir {
              flex: none;
              width: 80px;
              font-family: "Open Sans", Arial, serif;
              font-size: 12px;
              font-weight: 600;
            }

            &.price {
              font-family: "Open Sans", Arial, serif;
              font-size: 12px;
              flex: none;
              text-align: right;
              font-weight: 600;
            }
          }
        }
      }

      &.footer {
        align-items: center;

        border-top:1px solid rgba(151, 151, 151, 0.27);
        padding-top: 12px;

        .col-1 {

        }
        .col-2 {
          flex: 1;
          text-align: left;
          font-size: 16px;
          font-weight: 700;

          .price {
            font-family: "Open Sans", Arial, serif;
            font-size: 14px;
          }
        }
      }

    }
  }
}