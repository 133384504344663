.seats-table {
  .p-num {
    width: 32px;
    height: 32px;
    border-radius: 50%;
    background-color: #f3f3f3;
    color:#464646;
    font-size:18px;
    font-weight: 700;
    text-align: center;
    line-height: 32px;
  }

  .row {
    margin-top: 7px;
    display: flex;

    &.header {
      margin-top: 20px;

      color: #b3b3b3;
      font-size: 14px;
      font-weight: bold;
    }

    .blue-button-disabled {
      pointer-events: none;
    }

    .col {
      margin-right: 25px;
      min-height: 1px;
      display: inline-block;

      &:first-child {
        margin-right: 0;
      }
      &.c-num {
        width: 31px;
      }

      &.c-name {
        width: 190px;
      }

      &.c-seat {
        width: 72px;
        .grey-box {
          font-family: "open sans", Arial, serif;
          font-size:13px;
        }
      }

      &.c-button {
        width: 114px;

        .btn {
          position: relative;
          margin-top: -7px;
        }
      }

      &.c-direction {
        width: 93px;
      }
      &.c-baggage {
        width: 281px;

        .sub-row {
          margin-top: 10px;
          &:first-child {
            margin-top: 0;
          }

          &.message {
            margin-top: 1px;
            padding-right: 3px;
            font-size: 13px;
            color:#878789;
          }
        }
      }

      &.c-price {
        width: 70px;

      }
    }

    &:not(.header) {
      .col {
        &.c-name {
          color:#878789;

          >.wrap {
            height: 32px;
            border-radius: 4.5px;
            background-color: #f3f3f3;
            line-height: 32px;
            box-sizing: border-box;
            padding: 0 10px;
          }
        }
        &.c-price {
          font-size:23px;
          font-weight: 700;
        }
      }

    }
  }

  .row-disabled {
    opacity: 0.4;
    cursor: not-allowed;
  }

  .box-disabled {
    opacity: 0.4;
    cursor: not-allowed;
  }
}