
.extra-service {
  position: relative;
  display: flex;
  align-items: center;
  padding-top: 16px;
  padding-right: 30px;

  .currency,
  .price {
    font-family: "Open Sans", "Arial", serif;
    font-size:13px;
  }

  .extra-col-1 {
    width: 586px;
  }

  h4 {
    font-size: 25px;
    font-weight: 700;
    margin-bottom: 6px;
    margin-top: 0;
  }
  
  .btn {
    width: 80px;
    margin-right: 80px;
  }

  p {
    width: 480px;
    margin-top: 0;
    margin-bottom: 6px;
  }

  &__price {
    margin-right: 50px;

    div {
      &:first-child {
        opacity: 0.4;
        font-size: 13px;
        margin-bottom: 6px;
      }
      &:last-child {
        font-size: 23px;
        font-family: "Open Sans", "Arial", serif;
        font-weight: 700;
      }
    }
  }

  .first-service {
    margin-top: 10px;
  }
  .purchased-mark {
    position: absolute;
    right: 0;
    top: 15px;
  }
  .purchased-icon {
    width: 24px;
    height: 20px;
    background-size: 24px 20px;
  }
}