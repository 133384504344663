@import 'app/styles/helpers';
@import 'app/styles/theme';

.cs-sidebar {
  border-radius: 8px;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16), 0 3px 6px 0 rgba(0, 0, 0, 0.23);
  background: rgb(43,63,72);
  background: linear-gradient(0deg, rgba(43,63,72,1) 0%, rgba(32,121,166,1) 100%);

  @if ($theme == "bluebird") {
    background: #164397;
    background: linear-gradient(0deg, #164397 0%, #164397 50%, #7498ce 100%);
  }

  color:#FFFFFF;

  box-sizing: border-box;
  padding: 20px 20px 20px 6px;
  position: relative;
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;

  justify-content: flex-start; /* align items in Main Axis */
  align-items: stretch; /* align items in Cross Axis */
  align-content: stretch; /* Extra space in Cross Axis */

  .cs-sidebar-area {
    display: flex;
    flex-direction: row;
    flex:1;

    justify-content: flex-start; /* align items in Main Axis */
    align-items: stretch; /* align items in Cross Axis */
    align-content: stretch; /* Extra space in Cross Axis */
  }

  .cs-sidebar-footer {
    padding-left: 14px;
    margin-top: 10px;
  }

  .sidebar-row {
    &.body {
      display: flex;
      align-items: stretch;
      flex-direction: column;
    }

    &.button {
      display: flex;
      .btn-regular {
        margin-left: 15px;
      }
    }
  }

  .cs-sidebar-title {
    font-size: 22px;
    font-weight: bold;
  }

  .dates {
    display: flex;
    font-size:14px;

    .num {
      font-size:11px;
    }

    div {
      flex:1;
    }
  }

  .btn {
    &.continue {
      height: 57px;
      border-radius: 4px;
      background-color: $mainButtonBg;
      text-align: center;
      font-size: 20px;
      font-weight: bold;
      line-height: 57px;
      cursor: pointer;
      @include transition(background, 0.5s, ease);
      position: relative;
      flex:1;
      padding-left: 20px;

      &:hover {
        background-color: $mainButtonBgHover;
      }

      .icon-arrow-next {
        position: absolute;
        left:16px;
        top:21px;
      }
    }
  }

  .ScrollbarsCustom {

    .ScrollbarsCustom-Wrapper {
      position: absolute;
      inset: 0px 0px 0px 14px;
      overflow: hidden;
    }

    .ScrollbarsCustom-Track {

      .ScrollbarsCustom-ThumbY {
        background: #FFFFFF;
      }
    }

    .ScrollbarsCustom-Content {
    }
  }
}

.cs-sidebar-row {
  border-top:1px solid rgba(151, 151, 151, 0.2);
  padding: 8px 0 10px 0;
  margin-top: 10px;


  .title {
    font-size: 19px;
    font-weight: bold;
    margin-bottom: 9px;
  }

  .alert {
    display: flex;
    font-size:16px;
    color:rgba(255,255,255,0.4);
    align-content: center;
    align-items: center;
    font-weight: 700;

    .icon-baggage-b,
    .icon-seats-b {
      margin-left: 16px;
    }
  }

  &.bottom {
    display: flex;
    padding-top: 29px;
    align-items: center;
  }

  .col-txt-final {
    font-size: 20px;
    font-weight: bold;
    color:rgba(255,255,255,0.75);
    flex: 1;
    text-align: left;
    align-items: center;
    justify-content: left;
  }
  .col-txt-price {

    font-size: 28px;
    font-weight: bold;
    color:#ff8103;
    margin-right: 25px;
  }
}

.web-check-row {
  border-color: #eeeeee;
  padding: 25px 0 15px 0;
  margin: 0;
}
.cs-sidebar-row:first-child.web-check-row {
  border-top: none;
}

.web-check-wrapper {
  width: 300px;

  .cs-deal {
    padding-right: 23px;
    border-right: solid 1px #eeeeee;
  }

  .alert {
    color: #464646;
    .icon-baggage-b {
      display: none;
    }
  }
}