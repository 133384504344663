@import 'app/styles/helpers';
@import 'app/styles/theme';

.dashboard-steps {
    display: flex;
    width: 671px;

    .cs-d-step {
        position: relative;
        box-sizing: border-box;
        display: flex;
        width: 127px;
        padding: 10px;
        height: 55px;

        border-radius: 27.5px;
        background-color: rgba(32, 119, 162, 0);
        margin-right: 10px;

        cursor: pointer;

        @include transition(background-color, 0.5s, ease);


        &:first-child {
            margin-right: 0;
        }

        &:hover {
            background-color: rgba(32, 119, 162, 0.06);

            .edit-icon {
                opacity: 1;
            }

            .num {
                opacity: 0;
            }

            &:first-child {
                .icon-first-edit {
                    opacity: 1;
                }
            }
        }

        .num {
            width: 34px;
            height: 34px;
            border-radius: 50%;
            background-color: $stepBackgroundColor;
            

            text-align: center;
            font-family: "Open Sans", Arial, serif;
            font-size: 19px;
            font-weight: bold;
            color:#01527a;
            line-height: 34px;
            margin-left:8px;
            flex:none;
            opacity: 1;
            @include transition(opacity, 0.5s, ease);

        }

        .step-title {
            font-family: "almoni-dl", Arial, serif;
            font-size: 20px;
            font-weight: bold;
            color:$stepTextColor;
            line-height: 0.83;
            letter-spacing: normal;
            text-align: right;
            display: flex;
            align-items: center;
            justify-content: center;
        }

        .mark {
            width: 34px;
            height: 34px;
            right:10px;
            top:10px;
            position: absolute;
            @if ($theme == "bluebird") {
                background-image: url('~app/desktop/images/bluebird/mark.png');
            } @else {
                background-image: url('~app/desktop/images/mark.png');
            }
            background-size: 34px 34px;
            opacity: 0;
            @include transition(opacity, 0.5s, ease);
        }

        .edit-icon {
            width: 34px;
            height: 34px;
            right:10px;
            top:10px;
            position: absolute;
            @if ($theme == "bluebird") {
                background-image: url('~app/desktop/images/bluebird/edit-icon.png');
            } @else {
                background-image: url('~app/desktop/images/edit-icon.png');
            }
            
            background-size: 34px 34px;
            opacity: 0;
            @include transition(opacity, 0.5s, ease);
        }

        &.active {
            .num {
                opacity: 1;
                background-color: $stepActive;
                color: #ffffff;
            }

            cursor: default;

            &:hover {
                background-color: inherit;

                .edit-icon {
                    opacity: 0;
                }
            }

            &:first-child {
                .icon-first-edit {
                    opacity: 1;
                }
            }
        }

        &.marked {
            .mark {
                opacity: 1;
            }
            &:hover {
                background-color: inherit;

                .mark {
                    opacity: 0;
                }
                .edit-icon {
                    opacity: 1;
                }
            }

            .num {
                background-color: rgba(32,118,162,255);
            }
        }

        &.disabled {
            cursor: default;
            opacity: 0.3;

            &:hover {
                background-color: inherit;

                .edit-icon {
                    opacity: 1;
                }

                .num {
                    opacity: 1;
                }
            }
        }

        .icon-first {

            @if ($theme == "bluebird") {
                background-image: url('~app/desktop/images/bluebird/icon-info.png');
            } @else {
                background-image: url('~app/desktop/images/icon-info.png');
            }
            //background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAEQAAABECAMAAAAPzWOAAAABTVBMVEUAAAD////////////////M///V///j4//M5ubR6Ojb7e3M3e7P3+/M5ubO5+fT3unQ4+zM3ebP3+fS4enM4unM3+bQ4efM3ejN3unP3+TQ3ufM3+jN3+jP4OXP3OXN3OjO3eTO3eXM3ubN3+bN3+bP3efO3uXM3+bN3ObN3+XO3eXN3ubO3ubO3eXN3ObM3eTN3uXO3eTM3uXN3OXO3OTM3OTN3eXN3eXN3ObO3eTN3eXN3uXN3eTM3uXN3eXM3eXM3ubN3OTM3eXN3eXN3eXN3eXM3eTN3OXN3eXM3OTM3eXM3eXN3OTM3eTN3eTN3eTM3eTM3OXN3OXM3eXM3eXN3OTM3OTM3OXM3OTN3OTM3eXM3eXN3eTN3eTM3eTM3OXM3OXN3OXN3eXM3eXM3eTN3OTM3OTM3OXM3eXN3eXN3eUBUnpSiqWkwc/M3OSwmzZVAAAAa3RSTlMAAQIDBAUGCQoLDg8QFBUXGx4gIiMoKy0uMDY3ODo7QkNERkdISk5QUVdZXF1iZ2lscnR1fH1/gISGiYqQkZScoKKmp66ztLe7vsPEx8nK0dPU1tfY29zi4+Tn6O7v8PHy8/T19vn6+/z9/s4cIRwAAAHuSURBVHjapdCDmhxREEDh05u1bdu2bdtGzySp9/8YdLDD26j/AU6BpFJrBmY3ji/v7eebs+2FkbZs/MofXH+UaPbeVCXeWR1rH5LQwVAOnnzpOZbk7ifzcNdwImZPw6mYFSyLu8N6TBquxQt7NIVkrPGQeLSZR2Kpi+LdaRGJZKyLH1flxEtdF39uSohlLYpf5wXEGBf/9tOJ0hCSAOaIVHAtgXQSYUWCucmNOEaCmuef1BMJKlTFX70S3CoOrGNJ7qtDkgqX4+gQRUSWcKypIq/ZAPkfqoj0AQyKLrIFsK6MvGdB6qMyIq1QK0bfHWIyDQOitQmzonUBG2L0zSEm4UyORftYKeNKH6nmQR9pxNZH2nnRR5q50UfqONNHKtjWR/JZUEduYUQd2YU2dWQBsm1tpBfY00YKgSll5BSgUhmZ4LcDXaSU34ZUkR0cOfei0AmOSQnuyAJH3pME1s0/wxLUjsU/qYcSjF3Jp3pbApkh0pgEsZdGpJRN8e+umGh5p+LXWxOxiq7En1AX8cpv/DX6SaTk3M8tXSRWsC9e3TWRTPqceLNXjEHnjbizZ9Iwyp0PiYudSlxVrYbF4KjbwovypVdJYqfTwqvsvq13iXM6UYo/Wa3Tmxdh+et2d6G3kGAyy6ob21vqKvIx+gmgbfP2oMJ1mQAAAABJRU5ErkJggg==);
        }

        .icon-first-edit {
            @if ($theme == "bluebird") {
                background-image: url('~app/desktop/images/bluebird/icon-info-hover.png');
            } @else {
                background-image: url('~app/desktop/images/icon-info-hover.png');
            }
            //background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAEQAAABECAMAAAAPzWOAAAABTVBMVEUAAAD///+AgP9VqqpAgL8zmcwrgKo5jqozgLMui6IkgKQid6owgK8mgKYkeaoheqYme6oid6ooeKcmeKUkfKgmeaYkd6Yid6QheqYleqUhdqUgeaIkdqQjd6MjeaQjeKIieqQieKUheKQgd6UjeKMieaUheaMjeaMjeKQgeKQieKMhd6QheaUieKQgd6Mid6MhdqMid6Mhd6MheKMhd6IhdqMgdqMieKMheKIgeKQid6QhdqMid6MheKQhd6Qhd6MheKMhdqIgdqMgd6Mgd6MhdqIhd6MgdqMhd6IgdqIhd6IhdqMgd6Mhd6Ihd6MhdqIhdqMgd6Igd6Igd6IhdqMhd6MgdqIhdqIhd6Mgd6IgdqIhd6Mgd6MgdqIhd6Mhd6MhdqIhd6MgdqMgd6IgdqMhd6MhdqIhd6MgdqMgd6IgdqMgdqJMkbSlyNn///8zq7UEAAAAa3RSTlMAAQIDBAUGCQoLDg8QFBUXGx4gIiMoKy0uMDY3ODo7QkNERkdISk5QUVdZXF1iZ2lscnR1fH1/gISGiYqQkZScoKKmp66ztLe7vsPEx8nK0dPU1tfY29zi4+Tn6O7v8PHy8/T19vn6+/z9/s4cIRwAAAHtSURBVHjapdADmh1REEDh05Oxbdu2bdtGv6kk+/8YDJ9at/4FnAKOEit6Jlf2T6/t+4uj9ZmBpnSCyu5dvpVI9tZYKf5ZLUsvEtdOXwa+/OjYF2fXo1l4qzkQd3f9ibjLmRdvu9W4qTkXP+zBBJxYwyHxaTWL+BJnxb/DPOJJWZYgzoqJlbgswVwUEM2alaCOc4gyLMFtJxOhJiQGpgiXcy5GWgmzIGYuMsOOEVPTfEo8EFOhMj50irlFeGPti7Pfb8TRazFvWkQRkTneLKkij+kA2S+qiHQB9IousgawrIw8p0HirTIijVAprn6+ETfj0CNaqzApWiewIq5+vRE3r6nsi/axUsSZPlLOjT5Si62PNPOgj9RzoY9UcaSPlLCuj2Qzo45cwoA6sglN6sgMpNvaSCewpY3kAmPKyCFAqTIywn87ukgh//WpIhu8ybgWhVZ4Myrm9ix4k3Unxtr51C+mNiw+Je6KGbuUb9W2GJkg3JCY2EoiXMKqBHeVT6SsQwnqqY5oeWcSTKiNWMUXwRrdxFNwHOSWNuLL2fb/0zqcJE+JP1v5uGi9EG/2RBKuMqdD4mGjFE9li6/iYq/dwo/iuUdxsNFq4Vd619qzxDgcKSSYtMbx1ZOvwy43ZzpzMZNaVF7b3FBVko2rv+Wdz1e8OqKAAAAAAElFTkSuQmCC);
        }

        .icon-first,
        .icon-first-edit {
            position: absolute;
            right:10px;
            top:10px;
            width: 34px;
            height: 34px;
            background-size: 34px 34px;
            display: none;
            opacity: 0;
            @include transition(opacity, 0.5s, ease);

        }

        &:first-child {
            .icon-first,
            .icon-first-edit {
                display: block;
            }

            .icon-first {
                opacity: 1;
            }
        }

    }



}