.page-success {
  position: relative;

  .success-image {
    background-image: url('~app/desktop/images/payment.success.png');
    width: 119px;
    height: 153px;
    background-size: 119px 153px;
    margin: 100px auto 15px auto;
  }

  .success-text {
    font-family: "almoni-dl", Arial, serif;
    font-size: 35px;
    font-weight: 700;
    width: 352px;
    color:#01527a;
    margin: auto;
    text-align: center;

    .small {
      font-size: 28px;
    }
  }

  .button-placeholder {
    margin: 38px auto 0 auto;
    width: 205px;

  }

  .customers-text {
    font-family: "almoni-dl", Arial, serif;
    font-weight: 700;
    width: 480px;
    padding: 40px 0;
    color: #01527a;
    margin: auto;
    text-align: center;
    font-size:16px;
    margin-bottom: 100px;
  }

  .default-link{
    text-decoration: underline;
  }


}

.bottom-left-button {
  position: absolute;
  bottom: 10px; // Increase this value as needed
  left: 10px; // Increase this value as needed
  // Other styling for the button
}