@import 'app/styles/theme';

.cs-dashboard {
  width: 100%;
  height: 100%;

  display: flex;
  flex-direction: column;

  justify-content: flex-start; /* align items in Main Axis */
  align-items: stretch; /* align items in Cross Axis */
  align-content: stretch; /* Extra space in Cross Axis */

  .cs-footer {
    height: 130px;
    text-align: left;
    font-family: "Open Sans", Arial, serif;
    font-size:12px;
    display: flex;
  }

  .cs-header {
    width: 100%;
    height: 250px;
    position: relative;
    margin-bottom: -70px;
    flex: 0 0 auto;

    .header-row {
      margin-top:40px;
      padding-left:32px;
      display: flex;
      justify-content: left;
    }

    .main-logo {
      width: 213px;
      height: 114px;
      position: absolute;
      right: -34px;
      top: 0;
      cursor: pointer;
    }

    .cs-background {
      width: 100%;
      height: 250px;

      @if ($theme == "bluebird") {
        background: url('~app/desktop/images/bluebird/top-bg-a-3@2x.jpg') no-repeat top center;
      } @else {
        background: url('~app/desktop/images/top-bg-a-3@2x.jpg') no-repeat top center;
      }

      background-size: 1920px 250px;
      margin:auto;
      position: absolute;
    }
  }

  .container {
    width: 1020px;
    margin: auto;
    position: relative;
    &.main {
      display: flex;
      max-height: calc(100vh - 310px);
      min-height: 378px;
      flex:1;
     // overflow: hidden;
    }
  }

  .main-col-1 {
    width: 709px;
  }

  .main-col-2 {
    width: 291px;
    padding-top: 90px;
    margin-right: 20px;
  }

  .main-col-1,
  .main-col-2 {
    display: flex;
    flex-direction: column;
  }

  .footer-links-container {
    display: flex;
    float: right;
    flex-direction: row;
    font-size: 18px;
  }

  .footer-link {
    padding: 0 20px;
  }

  .webcheck-link {
   // display: none;
  }

  .footer-line {
    border-top: 1px solid #eeeeee;
    width: 650px;
  }
}