@import 'app/styles/helpers';

.payment-page-wrapper {
  display: flex;
  flex-direction: row;
  //justify-content: space-between;
  gap: 68px;
}


.payment-page {
  margin-top: 28px;

  .payment-form {
    label {
      color: #b3b3b3;
      font-size: 14px;
      font-weight: bold;
      display: block;
      margin-bottom: 3px;
    }
    .row {
      display: flex;
      margin-top: 20px;

      &:first-child {
        margin-top: 0;
      }

      .col {
        margin-right: 35px;
        width: 270px;

        &:first-child {
          margin-right: 0;
        }
      }
    }
  }



  .payment-bottom {
    margin-top: 100px;

    .icons-placeholder {
        text-align: left;
    }
    .controls {
      border-top:1px solid #cacaca;
      margin-top: 6px;
      padding-top: 34px;
      text-align: left;
      padding-left: 96px;
    }
  }

  .error-message-wrapper {
    direction: ltr;
    position: absolute;
    left: 50%;
    bottom: 30%;
  }

}