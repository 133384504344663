@import 'app/styles/helpers';

.cs-modal {
  position: fixed;
  width: 100%;
  height: 100%;
  visibility: hidden;
  opacity: 0;
  left:0;
  top:0;
  &.modal {
    background-color: rgba(255, 255, 255, 0.85);
  }

  @include transition(all, 0.5s , ease);

  &.open {
    visibility: visible;
    opacity: 1;
    z-index: 1;

    .cs-modal-wrap {
      right: 0;
    }
  }

  .cs-modal-wrap {
    right: 0;
    display: flex;
    align-items: center;
    text-align: center;
    height: 100%;
    width: 100%;
  }

}