@import 'app/styles/helpers';

.btn-cancel {
  @include transition(all, 0.5s, ease);


  border-radius: 4px;
  background-color: #FFFFFF;
  text-align: center;
  font-size:25px;
  font-weight: 700;
  color: #2076a2;
  height: 52px;
  line-height: 52px;
  cursor: pointer;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.2);

  &:hover {
    background-color: rgba(1, 82, 122, 0.1)
  }
}