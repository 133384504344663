@import 'app/styles/helpers';
@import 'app/styles/theme';

.btn-main {
  @include transition(all, 0.5s, ease);

  display: block;
  border-radius: 4px;
  background-color: $mainButtonBg;
  text-align: center;
  font-size:25px;
  font-weight: 700;
  color: #ffffff;
  height: 52px;
  line-height: 52px;
  width: 100%;
  cursor: pointer;

  &:disabled {
		opacity: 0.45;
		pointer-events: none;
	}

  &:hover {
    background-color: $mainButtonBgHover;
  }
}

.btn-payment {
  position: relative;
  cursor: pointer;
  .generic-tooltip {
    opacity: 0;
    visibility: hidden;

  }

    >.wrap {
      height: 57px;
      border-radius: 4px;
      background-color: $mainButtonBg;
      text-align: center;
      font-size: 20px;
      font-weight: bold;
      line-height: 57px;
      color:#FFFFFF;
      display: inline-block;
      padding: 0 16px;

      @include transition(background, 0.5s, ease);
      position: relative;
      padding-right: 40px;


    }


    .icon-lock {
      position: absolute;
      top:21px;
      right: 16px;
    }



  &:hover {
    >.wrap {
      background-color: $mainButtonBgHover;
    }

  }

  &.disabled {
    cursor: default;
    pointer-events: none;

    &:hover {
      .generic-tooltip {
        visibility: visible;
        opacity: 1;
      }
    }


    >.wrap {
      opacity: 0.5;

      &:hover {
        background-color: $mainButtonBg;


      }
    }

  }
}