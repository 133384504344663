.cs-extra-services-details {
  display: flex;
  align-items: center;

  .section-title {
    width: 278px;

    svg {
      height: 20px;
      width: 20px;
      margin-left: 8px;
    }
  }

  .section-logo {
    align-items: center;
    display: flex;

    .icon-v {
      margin-left: 14px;
    }

    & > div {
      display: flex;
      flex-direction: column;
    }

    .cs-bluebird-vip-logo {
      gap: 2px;
      margin-left: 2px;

      svg {
        height: 24px;
        &:first-child {
          width: 22px;
          margin-top: -10px;
        }
        &:last-child {
          width: 150px;
        }
      }
    }
  }

  .section-popup {
    margin-right: auto;
    a {
      font-weight: 700;
    }
  }
}