.dlg-extra-services-details {
  
  .dlg-title {
    svg {
      height: 24px;
      width: auto;
      margin-left: 12px;
    }
  }

  &-title {
    display: flex;
    align-items: center;
    margin-bottom: 26px;

    .cs-bluebird-vip-logo {
      svg {
        height: 32px;
        width: auto;

        &:first-child {
          margin-top: -14px;
        }
      }
    }

    h2 {
      margin: 0;
      margin-right: 10px;
    }
  }

  &-type {
    color: rgba(64, 64, 66, .4);
    font-size: 14px;
    font-weight: 700;
    margin-bottom: 6px;
  }

  .service-title {
    font-size: 25px;
    font-weight: 700;
    margin-bottom: 4px;
    & ~ div {
      margin-bottom: 20px;
    }
  }
}