.load-error {
  text-align: center;
  font-size:18px;
  color: #01527a;
  line-height: 1.22;
  .title {
    font-size: 25px;
    font-weight: 700;
  }

  .btn {
    display: inline-block;
  }

  .err-msg {
    max-width: 200px;
    margin: auto;
  }

}