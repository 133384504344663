@import 'app/styles/helpers';

.btn {
  &.btn-blue {
    height: 46px;
    border-radius: 4px;
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.2);
    background-color: #ebf8ff;
    font-size: 16px;
    color: #2076a2;
    text-align: center;
    font-weight: 700;
    font-family: "almoni-dl", Arial, serif;
    line-height: 47px;
    @include transition(all, 0.5s, ease);
    .symbol {
      font-family: "open sans", Arial, serif;
      font-size: 25px;
      display: inline-block;
      vertical-align: middle;
      line-height: normal;
      height: 37px;
      margin-left: 4px;
    }
    position: relative;
    cursor: pointer;

    &.big {
      height: 57px;
      line-height: 57px;
      font-size: 20px;
      padding: 0 16px;

      &.with-icon {
        padding-left: 45px;
      }

      &.disabled {
        pointer-events: none;
        opacity: 0.5;
      }

      .icon-arrow-next-blue {
        position: absolute;
        left:16px;
        top:20px;
      }
    }

    &:hover:not(:disabled) {
      background-color: rgba(1, 82, 122, 0.1)
    }

    .generic-tooltip {
      font-size: 14px;
      font-weight: 400;
        .text {
          line-height: initial;
          padding: 10px 18px;
          text-align: right;
          height: auto;
        }
    }

    &:hover {
      .generic-tooltip {
        visibility: visible;
        opacity: 1;
      }
    }
    &:disabled {
      background-color: rgba(235, 248, 255, .45);
      color: rgba(32, 118, 162, .45);
    }
  }

  &.pink-button {
    background-color: #ffebeb;
    color: #ff4f4f;

    &:hover:not(:disabled) {
      background-color: rgba(255, 80, 80, 0.1)
    }
  }

  &.isCheckIn {
    padding: 0 5px !important;
  }

  &.mobile-width {
    width: 100%;
    justify-content: center;
  }

  
}

//Mobile
@media only screen and (max-width: 1024px) {

  .btn {
    &.btn-blue {
      font-size: get_vw_from_px(12px);
    }
  }
}