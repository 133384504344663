.dlg-seats-booking {
    width: 420px;
    height: 420px;
    padding: 35px 29px 35px 29px;
    border-radius: 4px;
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16), 0 3px 6px 0 rgba(0, 0, 0, 0.23);
    background-color: #ffffff;
    margin: auto;
    box-sizing: border-box;
    >.wrap {
      position: relative;


    }

    .ui-loader {
        margin: 75px auto 0 auto;
    }
}