.cs-flight-data-webcheck {
  padding: 40px 0;
  border-bottom: 1px solid #eeeeee;
}

.cs-flight-data {
  display: flex;

  .details-col {
    font-size:16px;
    align-items: center;
    justify-content: center;
    display: flex;
    font-weight: 700;
  }

  .cs-flight {
    flex:none;
    width: 278px;


    .cs-deal-row {
      margin-top: 3px;

      &.small {
        font-size:16px;
      }

      &.name {
        margin-top: 8px;
        font-size:16px;
        color:#464646;
      }
    }

    .time {
      font-family: "Open Sans", Arial, serif;
      font-size:14px;
      font-weight: 600;
    }

    .date {
      font-family: "Open Sans", Arial, serif;
      font-size:14px;
      font-weight: 700;
    }

    .day {
      font-weight: 700;
      font-size:16px;
    }

    .title {
      font-size:17px;
      font-weight: 700;
    }
  }
}